import React, { Dispatch, SetStateAction } from "react";
import { AccessRuleUpdate } from "../../types/AccessRule";
import { Flex } from "../elements/flex/Flex";
import { SecondaryButton } from "../elements/buttons/SecondaryButton";
import { MainButton } from "../elements/buttons/MainButton";
import { Text } from "../elements/text/Text";
import { Input } from "../elements/input/Input";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";
import { DateTime } from "luxon";
import { TimezoneSelect } from "../shared-components/TimezoneSelect";

type Props = {
  accessRuleValues: AccessRuleUpdate;
  setAccessRuleValues: Dispatch<SetStateAction<AccessRuleUpdate>>;
  onNext: () => void;
  onBack: () => void;
  inProgress: boolean;
};

const labelWidth = "150px";
const inputWidth = "300px";

export const AccessRuleFormStepTwoOneTime = ({
  accessRuleValues,
  setAccessRuleValues,
  onNext,
  onBack,
  inProgress,
}: Props) => {
  const [selectedTimezone, setSelectedTimezone] = React.useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  // Converts from selected timezone time to UTC
  const convertToUTC = (localDateStr: string, timezone: string) => {
    if (!localDateStr) return "";
    const dt = DateTime.fromISO(localDateStr, { zone: timezone });
    return dt.toUTC().toFormat("yyyy-MM-dd'T'HH:mm");
  };

  // Converts from UTC to selected timezone
  const convertFromUTC = (utcStr: string, timezone: string) => {
    if (!utcStr) return "";
    const dt = DateTime.fromISO(utcStr, { zone: "utc" }).setZone(timezone);
    return dt.toFormat("yyyy-MM-dd'T'HH:mm");
  };

  const handleDateChange = (
    field: "start_date" | "end_date",
    localValue: string
  ) => {
    const utcValue = convertToUTC(localValue, selectedTimezone);
    setAccessRuleValues({
      ...accessRuleValues,
      [field]: utcValue,
    });
  };

  return (
    <Flex padding="24px" column gap="16px">
      <Text variant="h4">Select Dates</Text>
      <TimezoneSelect
        selectedTimezone={selectedTimezone}
        onChange={setSelectedTimezone}
        labelWidth={labelWidth}
        inputWidth={inputWidth}
      />

      <Flex gap="16px" align="center">
        <Text variant="body1" style={{ width: labelWidth }}>
          Start Date
        </Text>
        <Input
          type="datetime-local"
          style={{ width: inputWidth }}
          value={convertFromUTC(
            accessRuleValues.start_date || "",
            selectedTimezone
          )}
          onChange={(e) => handleDateChange("start_date", e.target.value)}
        />
      </Flex>

      <Flex gap="16px" align="center">
        <Text variant="body1" style={{ width: labelWidth }}>
          End Date
        </Text>
        <Input
          type="datetime-local"
          style={{ width: inputWidth }}
          value={convertFromUTC(
            accessRuleValues.end_date || "",
            selectedTimezone
          )}
          onChange={(e) => handleDateChange("end_date", e.target.value)}
        />
      </Flex>

      <Flex gap="16px" align="center">
        <Text variant="body1" style={{ width: labelWidth }}>
          One time use
        </Text>
        <SwitchCheckbox
          checked={!!accessRuleValues.one_time_use}
          onChange={(e) =>
            setAccessRuleValues({
              ...accessRuleValues,
              one_time_use: e.target.checked,
            })
          }
        />
      </Flex>

      <Flex w100 justify="between" align="center">
        <SecondaryButton onClick={onBack}>Back</SecondaryButton>
        <MainButton
          onClick={onNext}
          inProgress={inProgress}
          disabled={!accessRuleValues.start_date || !accessRuleValues.end_date}
        >
          Save
        </MainButton>
      </Flex>
    </Flex>
  );
};
