import styled from "styled-components";
import Empty from "../../../assets/illustrations/illustration-empty.png";
import Delete from "../../../assets/illustrations/illustration-delete.png";
import Search from "../../../assets/illustrations/illustration-search.png";

type Props = {
  name: "empty" | "delete" | "search";
  alt?: string;
};

const getImageSrc = (name: "empty" | "delete" | "search") => {
  switch (name) {
    case "empty":
      return Empty;
    case "delete":
      return Delete;
    case "search":
      return Search;
    default:
      return Empty;
  }
};

export const Illustration = styled.img.attrs<Props>(({ name, alt }) => ({
  src: getImageSrc(name),
  alt: alt || name,
}))<Props>``;
