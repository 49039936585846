import React from "react";
import ReactSelect, { GroupBase, OptionsOrGroups } from "react-select";
import { Option } from "../../../types/Shared";

export type SelectOption = Option | GroupBase<Option>;

type Props = {
  isDisabled?: boolean;
  value?: Option | null;
  options: OptionsOrGroups<Option, GroupBase<Option>> | Option[];
  onChange: (value: Option | null) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  isSearchable?: boolean;
  formatGroupLabel?: (data: GroupBase<Option>) => React.ReactNode;
};

export const Select: React.FC<Props> = ({
  isDisabled = false,
  value = null,
  options,
  onChange,
  placeholder = "Select...",
  style = {},
  isSearchable = true,
  formatGroupLabel,
}) => {
  return (
    <div style={style}>
      <ReactSelect
        isDisabled={isDisabled}
        className="react-select-container"
        classNamePrefix="react-select"
        placeholder={placeholder}
        value={value}
        options={options}
        onChange={(option) => onChange(option as Option)}
        isSearchable={isSearchable}
        formatGroupLabel={formatGroupLabel}
      />
    </div>
  );
};
