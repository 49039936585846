import { useState } from "react";
import { Flex } from "../elements/flex/Flex";
import { COLORS } from "../../assets/colors";
import { AccessRule } from "../../types/AccessRule";
import { Text } from "../elements/text/Text";
import { SecondaryButton } from "../elements/buttons/SecondaryButton";
import { AccessRuleFormModal } from "../access-rules/AccessRuleFormModal";
import { useIsMobile } from "../../utils/hooks";
import { ActivityLog } from "../../types/ActivityLog";
import { useApiGroupsData } from "../../context/groupContext";
import { useApiPersonsData } from "../../context/personsContext";
import { Group } from "../../types/Group";
import { Person } from "../../types/Person";

const parseChanges = (changes: string) => {
  try {
    if (!changes.includes("{") && !changes.includes(":")) {
      return changes; // Return the original string without modification
    }
    // Replace Python literals with JSON-safe values
    let fixedChanges = changes
      .replace(/None/g, "null") // Python None → JSON null
      .replace(/True/g, "true") // Python True → JSON true
      .replace(/False/g, "false") // Python False → JSON false
      .replace(/'/g, '"'); // Convert single quotes to double quotes
    //  Step 2: Convert OrderedDict to standard objects
    fixedChanges = fixedChanges
      .replace(/OrderedDict\(\[/g, "{") // Remove OrderedDict opening
      .replace(/\)]\)/g, ")}"); // Remove closing brackets
    // Step 3: Convert Tuples (key, value) → {"key": value}
    fixedChanges = fixedChanges.replace(
      /\{\s*\(\s*"([^"]+)"\s*,\s*(.*?)\)\s*(,\s*\(\s*"([^"]+)"\s*,\s*(.*?)\))?\s*\}/g,
      (match, key1, value1, _, key2, value2) => {
        const first = `"${key1}": ${value1.trim()}`;
        const second = key2 ? `, "${key2}": ${value2.trim()}` : "";
        return `{${first}${second}}`;
      }
    );
    // Step 3: Convert Tuples again (key, value) → {"key": value}
    fixedChanges = fixedChanges.replace(
      /\{\s*\(\s*"([^"]+)"\s*,\s*(.*?)\)\s*(,\s*\(\s*"([^"]+)"\s*,\s*(.*?)\))?\s*\}/g,
      (match, key1, value1, _, key2, value2) => {
        const first = `"${key1}": ${value1.trim()}`;
        const second = key2 ? `, "${key2}": ${value2.trim()}` : "";
        return `{${first}${second}}`;
      }
    );

    // Fix nesting and commas
    fixedChanges = fixedChanges
      .replace(/}\s*{/g, "},{")
      .replace(/\]\s*{/g, "],{")
      .replace(/}\s*\[/g, "},{")
      .replace(/,\s*}/g, "}");

    const parsed = JSON.parse(fixedChanges);

    // ✅ Step 6: Flatten "days" Only If Needed
    if (parsed.days && Array.isArray(parsed.days)) {
      parsed.days = parsed.days.map((day: any) => {
        if (Array.isArray(day)) {
          const flattenedDay = {};
          day.forEach((entry: any) => {
            Object.assign(flattenedDay, entry);
          });
          return flattenedDay;
        }
        return day;
      });
    }

    return parsed;
  } catch (error) {
    console.error("Parsing Error:", error);
    return changes; // Return the original string if parsing fails
  }
};

const convertGroupName = (groupId: string, groups: Group[]) => {
  const group = groups.find((group: Group) => group.id === groupId);
  return group ? group.name : undefined;
};
const convertPersonName = (personId: string, persons: Person[]) => {
  const person = persons.find((person: Person) => person.id === personId);
  return person ? person.person_name : undefined;
};

const getDayName = (dayIndex: number): string => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return daysOfWeek[dayIndex] || `Unknown Day (${dayIndex})`;
};

const renderDays = (days: any[]) => {
  return (
    <ul style={{ paddingLeft: "16px" }}>
      {days
        .filter((day) => day.time_ranges && day.time_ranges.length > 0)
        .map((day, index) => (
          <li key={index}>
            {getDayName(day.day_of_week)}:
            <ul>
              {day.time_ranges.map((range: any, idx: number) => (
                <li key={idx}>
                  From:{" "}
                  <span style={{ fontWeight: "bold" }}>{range.from_time}</span>{" "}
                  - To:{" "}
                  <span style={{ fontWeight: "bold" }}>{range.to_time}</span>
                </li>
              ))}
            </ul>
          </li>
        ))}
    </ul>
  );
};

const renderKeyValuePairs = (data: any, persons: Person[], groups: Group[]) => {
  if (data === null || data === undefined) return null;
  if (typeof data === "object" && !Array.isArray(data)) {
    return (
      <ul style={{ paddingLeft: "16px" }}>
        {Object.entries(data).map(([key, value]) =>
          value !== null ? (
            <li
              key={key}
              style={{ display: "flex", gap: "8px", alignItems: "start" }}
            >
              {key}:{" "}
              <strong>
                {key === "days" && Array.isArray(value)
                  ? renderDays(value)
                  : key === "person" && typeof value === "string"
                    ? convertPersonName(value, persons)
                    : key === "group" && typeof value === "string"
                      ? convertGroupName(value, groups)
                      : typeof value === "string" &&
                          (key === "created" || key === "updated")
                        ? new Date(value).toLocaleString()
                        : renderKeyValuePairs(value, persons, groups)}
              </strong>
            </li>
          ) : null
        )}
      </ul>
    );
  }
  if (Array.isArray(data)) {
    return (
      <ul style={{ paddingLeft: "16px" }}>
        {data.map((item, index) => (
          <li key={index}>{renderKeyValuePairs(item, persons, groups)}</li>
        ))}
      </ul>
    );
  }

  return <span style={{ fontWeight: "bold" }}>{String(data)}</span>;
};
type Props = {
  activityLog: ActivityLog;
  accessRule: AccessRule | null;
};

export const ActivityLogCard = ({ activityLog, accessRule }: Props) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const isMobile = useIsMobile();
  const { data: groups } = useApiGroupsData();
  const { data: persons } = useApiPersonsData();
  const changesData = parseChanges(activityLog.changes);
  const isDict = typeof changesData === "object";

  return (
    <Flex
      column={isMobile}
      w100
      justify="between"
      align="start"
      gap={isMobile ? "4px" : "24px"}
      style={{
        padding: "16px",
        borderRadius: "8px",
        backgroundColor: COLORS.Gray700,
      }}
    >
      {/* Action and Rule Name */}
      <Flex column align="start" w100>
        <Flex justify="center" align="end" gap="4px">
          <Text variant="body1">
            {`${activityLog.action?.charAt(0).toUpperCase()}${activityLog.action?.slice(1)} Rule: `}
          </Text>
          <Text variant="h6">{activityLog.rule_name}</Text>
        </Flex>
        <Text variant="caption">
          {new Date(activityLog.created || "").toLocaleString()}
        </Text>
      </Flex>

      {/* Performed By Section */}
      <Flex column align="start" w100>
        <Text variant="body1">Performed by:</Text>
        <Text variant="h6">{activityLog.performed_by}</Text>
      </Flex>

      {/* Changes Section */}
      <Flex column align="start" w100>
        <Text variant="body1">Changes:</Text>
        {isDict ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setExpanded(!expanded)}
          >
            <Text variant="body1" style={{ textDecoration: "underline" }}>
              {expanded ? "Hide Details" : "Show Details"}
            </Text>
            {expanded &&
              persons &&
              groups &&
              renderKeyValuePairs(changesData, persons, groups)}
          </div>
        ) : (
          <Text
            variant="h6"
            truncate
            title={activityLog.changes}
            style={{
              maxWidth: "350px",
            }}
          >
            {activityLog.changes.length > 50
              ? `${activityLog.changes.slice(0, 50)}...`
              : activityLog.changes}
          </Text>
        )}
      </Flex>
      <Flex align="start" gap="8px">
        {accessRule ? (
          <SecondaryButton onClick={() => setShowEditModal(true)}>
            Edit Rule
          </SecondaryButton>
        ) : (
          <div style={{ width: "70px", height: "36px" }}></div>
        )}
      </Flex>
      {accessRule && (
        <AccessRuleFormModal
          setShowModal={setShowEditModal}
          showModal={showEditModal}
          editAccessRule={accessRule}
        />
      )}
    </Flex>
  );
};
