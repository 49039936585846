import { getCookie, removeNullKeys } from "../utils/helper";

export const FIQ_STATICS_URL = "/static/fiq/";
export const IMAGE_BASE_PATH = "/static/";
export const VIDEO_BASE_PATH = "/media/";
export const BASE_API_URL =
  window.origin === "http://localhost:3000"
    ? "http://localhost:9000/api/v2"
    : "/api/v2";

const getBaseConfig = (method) => ({
  method,
  credentials: "include", // send cookie with JWT
  headers: {
    "Content-Type": "application/json",
    "X-CSRFToken": getCookie("csrftoken"),
    // Authorization: `Bearer ${cookie.load("jwt_token")}`,
  },
});

const handle401 = (resp) => {
  if (resp.status === 401) {
    window.location = `/login?next=${window.location.pathname}`;
    // notifyError('Unauthenticated.');
  }
  // if (resp.status === 403) {
  //   window.location = "/login";
  //   // notifyError('Unauthenticated.');
  // }
  return resp;
};

const serializeResponse = (response) => {
  return response
    .text()
    .then((text) => {
      try {
        var jsonResp = text ? JSON.parse(text) : {};
        if (
          !jsonResp.status &&
          response.status > 199 &&
          response.status < 300
        ) {
          jsonResp.status = 1;
        } else if (!jsonResp.status) {
          jsonResp.status = 0;
        }
        return jsonResp;
      } catch {
        console.error(
          `Failed to parse server response to JSON, server status: ${response.status}`
        );
        return text;
      }
    })
    .then((data) => data);
};

export const login = (username, password) =>
  fetch(`${BASE_API_URL}/jwt-token`, {
    method: "post",
    credentials: "include",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: `username=${username}&password=${password}`,
  })
    .then(serializeResponse)
    .then(handle401);

export const isLoggedIn = () =>
  fetch(`${BASE_API_URL}/me`, { ...getBaseConfig("get") }).then(
    (resp) => resp.status === 200
  );

export const get = (url, options) =>
  fetch(`${BASE_API_URL}/${url}`, { ...getBaseConfig("get"), ...options })
    .then(handle401)
    .then(serializeResponse);

export const post = (url, data, options) =>
  fetch(`${BASE_API_URL}/${url}`, {
    ...getBaseConfig("post"),
    ...options,
    body: JSON.stringify(removeNullKeys(data)),
  })
    .then(handle401)
    .then(serializeResponse);

export const put = (url, data, options) =>
  fetch(`${BASE_API_URL}/${url}`, {
    ...getBaseConfig("put"),
    ...options,
    body: JSON.stringify(removeNullKeys(data)),
  })
    .then(handle401)
    .then(serializeResponse);

export const deleteItem = (url, data, options) =>
  fetch(`${BASE_API_URL}/${url}`, {
    ...getBaseConfig("delete"),
    ...options,
    body: JSON.stringify(data),
  })
    .then(handle401)
    .then(serializeResponse);

export const exportPersons = () => {
  return fetch(`${BASE_API_URL}/persons/export`, {
    method: "GET",
  });
};

export const exportLogs = (
  is_android = false,
  camera_id = undefined,
  filename = undefined
) => {
  return fetch(
    `${BASE_API_URL}/support/${
      is_android
        ? `android_logs?camera_id=${camera_id}&filename=${filename}`
        : "logs"
    }`
  );
};

export const importPersons = (data) => {
  return fetch(`${BASE_API_URL}/persons/import_people`, {
    method: "post",
    headers: {
      "Content-length": data.length,
      "Accept-Encoding": "gzip,deflate",
      "Content-Type": "application/zip",
      "Content-Disposition": 'attachment; filename="impoertPeople.zip"',
    },
    body: data,
  })
    .then(handle401)
    .then(serializeResponse);
};
