import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import { Flex } from "../elements/flex/Flex";

type ThumbnailType = {
  src: string;
  title: string;
};

interface ThumbnailGalleryProps {
  thumbnails: ThumbnailType[]; // Array of image URLs
}

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ThumbnailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`;

const Thumbnail = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid ${COLORS.Gray100};
  border-radius: 8px;
`;

const PaginationDots = styled.div`
  display: flex;
  gap: 6px;
`;

const Dot = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: none;
  background-color: ${({ active }) =>
    active ? COLORS.Gray100 : COLORS.Gray600};
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.Gray50};
  }
`;

export const ThumbnailGallery: React.FC<ThumbnailGalleryProps> = ({
  thumbnails,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const { clientWidth, clientHeight } = containerRef.current;
      setContainerSize({ width: clientWidth, height: clientHeight });
    }
  }, []);

  const calculateThumbnailsPerPage = (): number => {
    const { width, height } = containerSize;
    const thumbnailWidth = 100 + 12; // 100px + 12px gap
    const thumbnailHeight = 100 + 10; // 100px + 10px gap
    const columns = Math.floor(width / thumbnailWidth);
    const rows = Math.floor(height / thumbnailHeight);
    return columns * rows;
  };

  const thumbnailsPerPage = Math.max(calculateThumbnailsPerPage(), 1);
  const totalPages = Math.ceil(thumbnails.length / thumbnailsPerPage);

  const paginatedImages = thumbnails.slice(
    currentPage * thumbnailsPerPage,
    (currentPage + 1) * thumbnailsPerPage
  );

  return (
    <GalleryContainer
      ref={containerRef}
      style={{
        color: COLORS.FontGray,
      }}
    >
      <ThumbnailsWrapper>
        {paginatedImages.map((thumbnail, index) => (
          <Flex column justify="center" align="center">
            <Thumbnail
              key={index}
              src={`${IMAGE_BASE_PATH}${thumbnail.src}`}
              alt={`Thumbnail ${index}`}
            />
            <label style={{ fontSize: "12px" }}>{thumbnail.title}</label>
          </Flex>
        ))}
      </ThumbnailsWrapper>
      {totalPages > 0 && (
        <PaginationDots>
          {Array.from({ length: totalPages }).map((_, pageIndex) => (
            <Dot
              key={pageIndex}
              active={pageIndex === currentPage}
              onClick={() => setCurrentPage(pageIndex)}
            />
          ))}
        </PaginationDots>
      )}
    </GalleryContainer>
  );
};
