import React, { Dispatch, SetStateAction, useEffect } from "react";
import { AccessRuleUpdate } from "../../types/AccessRule";
import { Flex } from "../elements/flex/Flex";
import { Text } from "../elements/text/Text";
import { SecondaryButton } from "../elements/buttons/SecondaryButton";
import { MainButton } from "../elements/buttons/MainButton";
import { AccessRuleDayConfig } from "./AccessRuleDayConfig";
import { TimezoneSelect } from "../shared-components/TimezoneSelect";

type Props = {
  accessRuleValues: AccessRuleUpdate;
  setAccessRuleValues: Dispatch<SetStateAction<AccessRuleUpdate>>;
  onNext: () => void;
  onBack: () => void;
  inProgress: boolean;
};

export const AccessRuleFormStepTwoCyclical = ({
  accessRuleValues,
  setAccessRuleValues,
  onNext,
  onBack,
  inProgress,
}: Props) => {
  const [selectedTimezone, setSelectedTimezone] = React.useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  useEffect(() => {
    setAccessRuleValues((currentAccessRuleValues) => {
      // initiate days for cyclical
      const allDays = Array.from({ length: 7 }, (_, i) => i);
      const existingDays = currentAccessRuleValues.days || [];
      const missingDays = allDays.filter(
        (day) => !existingDays.some((d) => d.day_of_week === day)
      );

      if (missingDays.length > 0) {
        return {
          ...currentAccessRuleValues,
          days: [
            ...existingDays,
            ...missingDays.map((day) => ({
              day_of_week: day,
              time_ranges: [],
            })),
          ].sort((a, b) => a.day_of_week - b.day_of_week),
        };
      }
      return currentAccessRuleValues;
    });
  }, [setAccessRuleValues]);

  return (
    <Flex padding="24px" column gap="16px">
      <Text variant="h4">Select Times</Text>
      <TimezoneSelect
        selectedTimezone={selectedTimezone}
        onChange={setSelectedTimezone}
        labelWidth="100px"
      />

      {accessRuleValues.days?.map((day) => (
        <AccessRuleDayConfig
          selectedTimezone={selectedTimezone}
          key={day.day_of_week}
          day={day}
          dayChanged={(newDay) => {
            setAccessRuleValues((currentAccessRuleValues) => {
              const newDays = currentAccessRuleValues.days?.map((d) =>
                d.day_of_week === newDay.day_of_week ? newDay : d
              );
              return {
                ...currentAccessRuleValues,
                days: newDays,
              };
            });
          }}
        />
      ))}

      <Flex w100 justify="between" align="center">
        <SecondaryButton onClick={onBack}>Back</SecondaryButton>
        <MainButton onClick={onNext} inProgress={inProgress}>
          Save
        </MainButton>
      </Flex>
    </Flex>
  );
};
