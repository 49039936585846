import React from "react";
import { COLORS } from "../../assets/colors";
import { Camera } from "../../types/Camera";
import CameraComponent from "./Camera";
import { Flex } from "../elements/flex/Flex";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

type ZoneProps = {
  cameras: Camera[];
  zone: string;
};

export function Zone(props: ZoneProps) {
  const { cameras, zone } = props;
  const [hideZone, setHideZone] = React.useState(false);

  return (
    <div
      style={{
        backgroundColor: COLORS.Gray700,
        borderRadius: "8px",
      }}
    >
      <Flex
        align="center"
        justify="between"
        padding="24px"
        gap="24px"
        className="text-uppercase text-truncate"
        style={{
          backgroundColor: COLORS.Gray800,
          height: "68px",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <label>{zone} ZONE</label>
        {hideZone ? (
          <FaChevronUp
            size={24}
            className="pointer"
            onClick={() => setHideZone(!hideZone)}
          />
        ) : (
          <FaChevronDown
            size={24}
            className="pointer"
            onClick={() => setHideZone(!hideZone)}
          />
        )}
      </Flex>

      {!hideZone && (
        <Flex align="center" justify="start" padding="24px" gap="24px" flexWrap>
          {cameras.map((camera) => (
            <CameraComponent key={camera.id} camera={camera} />
          ))}
        </Flex>
      )}
    </div>
  );
}
