import React, { FC } from "react";
import styled, { css } from "styled-components";

type Variant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "caption"
  | "overline";

type TypographyProps = {
  variant?: Variant;
  color?: string;
  align?: "left" | "center" | "right" | "justify";
  gutterBottom?: boolean;
  truncate?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  title?: string;
};

// Add truncate to TypographyBase props
const TypographyBase = styled.div<{
  variant: Variant;
  color?: string;
  align?: string;
  gutterBottom?: boolean;
  truncate?: boolean; // Add this line
}>`
  ${({ variant }) => {
    switch (variant) {
      case "h1":
        return css`
          font-size: 2.5rem;
          font-weight: 700;
          line-height: 1.2;
        `;
      case "h2":
        return css`
          font-size: 2rem;
          font-weight: 700;
          line-height: 1.3;
        `;
      case "h3":
        return css`
          font-size: 1.75rem;
          font-weight: 700;
          line-height: 1.4;
        `;
      case "h4":
        return css`
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 1.4;
        `;
      case "h5":
        return css`
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.5;
        `;
      case "h6":
        return css`
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.5;
        `;
      case "subtitle1":
        return css`
          font-size: 1.15rem;
          font-weight: 400;
          line-height: 1.75;
        `;
      case "subtitle2":
        return css`
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.75;
        `;
      case "body1":
        return css`
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
        `;
      case "body2":
        return css`
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.43;
        `;
      case "caption":
        return css`
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1.66;
        `;
      case "overline":
        return css`
          font-size: 0.75rem;
          font-weight: 700;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          line-height: 2.66;
        `;
      default:
        return css``;
    }
  }}

  color: ${({ color }) => color || "inherit"};
  text-align: ${({ align }) => align || "left"};
  margin-bottom: ${({ gutterBottom }) => (gutterBottom ? "1rem" : "0")};

  ${({ truncate }) =>
    truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

// Add truncate to Text component props
export const Text: FC<TypographyProps> = ({
  variant = "body1",
  color,
  align = "left",
  gutterBottom = false,
  truncate = false, // Add this line
  children,
  style,
  className,
  title,
}) => {
  return (
    <TypographyBase
      as={variant}
      variant={variant}
      color={color}
      align={align}
      gutterBottom={gutterBottom}
      truncate={truncate} // Add this line
      style={style}
      className={className}
      title={title}
    >
      {children}
    </TypographyBase>
  );
};
