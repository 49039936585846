import React from "react";
import { Flex } from "../elements/flex/Flex";
import { Text } from "../elements/text/Text";
import { DaySchedule } from "../../types/AccessRule";
import { TimeInput } from "../elements/input/TimeInput";
import { MainButton } from "../elements/buttons/MainButton";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { SecondaryButton } from "../elements/buttons/SecondaryButton";
import { DateTime } from "luxon";
import { useIsMobile } from "../../utils/hooks";

type Props = {
  day: DaySchedule;
  dayChanged: (day: DaySchedule) => void;
  selectedTimezone: string; // Add this prop to control displayed timezone
};

const dayToName = (dayNumber: number) => {
  switch (dayNumber) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    default:
      return "Unknown";
  }
};

// A reference date to apply times to (since we only have HH:mm)
const REFERENCE_DATE = "2020-01-01";

// Convert a UTC time string ("HH:mm") to local hours/minutes for TimeInput
const utcToLocalTime = (utcTime: string, timezone: string) => {
  if (!utcTime) return { hours: "", minutes: "" };
  const dt = DateTime.fromISO(`${REFERENCE_DATE}T${utcTime}:00`, {
    zone: "utc",
  }).setZone(timezone);
  return {
    hours: dt.toFormat("HH"),
    minutes: dt.toFormat("mm"),
  };
};

// Convert local hours/minutes from TimeInput to a UTC time string ("HH:mm")
const localToUtcTime = (hours: string, minutes: string, timezone: string) => {
  const dt = DateTime.fromISO(`${REFERENCE_DATE}T${hours}:${minutes}:00`, {
    zone: timezone,
  }).setZone("utc");
  return dt.toFormat("HH:mm");
};

export const AccessRuleDayConfig = ({
  day,
  dayChanged,
  selectedTimezone,
}: Props) => {
  const isMobile = useIsMobile();
  return (
    <Flex align="start">
      <Text
        variant="body1"
        style={{
          width: "120px",
        }}
      >
        {dayToName(day.day_of_week)}
      </Text>
      <Flex align="end" gap="16px">
        <Flex column gap="16px">
          {day.time_ranges.map((timeRange, index) => {
            const fromLocal = utcToLocalTime(
              timeRange.from_time,
              selectedTimezone
            );
            const toLocal = utcToLocalTime(timeRange.to_time, selectedTimezone);

            return (
              <Flex
                key={`day-${day.day_of_week}-${index}`}
                align="center"
                gap="16px"
                column={isMobile}
              >
                <Flex align="center" gap="8px">
                  <Text variant="body2">Start Time</Text>
                  <TimeInput
                    initialTime={
                      fromLocal.hours && fromLocal.minutes
                        ? fromLocal
                        : undefined
                    }
                    onTimeChange={(time) => {
                      const newFromTime = localToUtcTime(
                        time.hours,
                        time.minutes,
                        selectedTimezone
                      );
                      if (newFromTime !== day.time_ranges[index].from_time) {
                        dayChanged({
                          ...day,
                          time_ranges: day.time_ranges.map((tr, i) =>
                            i === index ? { ...tr, from_time: newFromTime } : tr
                          ),
                        });
                      }
                    }}
                  />
                </Flex>
                <Flex align="center" gap="8px">
                  <Text variant="body2">End Time</Text>
                  <TimeInput
                    initialTime={
                      toLocal.hours && toLocal.minutes ? toLocal : undefined
                    }
                    onTimeChange={(time) => {
                      const newToTime = localToUtcTime(
                        time.hours,
                        time.minutes,
                        selectedTimezone
                      );
                      if (newToTime !== day.time_ranges[index].to_time) {
                        dayChanged({
                          ...day,
                          time_ranges: day.time_ranges.map((tr, i) =>
                            i === index ? { ...tr, to_time: newToTime } : tr
                          ),
                        });
                      }
                    }}
                  />
                </Flex>
                <SecondaryButton
                  style={{
                    padding: "10px",
                  }}
                  variant="danger"
                  onClick={() => {
                    dayChanged({
                      ...day,
                      time_ranges: day.time_ranges.filter(
                        (_, i) => i !== index
                      ),
                    });
                  }}
                >
                  <FaMinus />
                </SecondaryButton>
              </Flex>
            );
          })}
        </Flex>
        <MainButton
          style={{
            padding: "10px",
          }}
          onClick={() => {
            // When adding a new time range, store it in UTC
            dayChanged({
              ...day,
              time_ranges: [
                ...day.time_ranges,
                {
                  from_time: "00:00",
                  to_time: "23:59",
                },
              ],
            });
          }}
        >
          <FaPlus />
        </MainButton>
      </Flex>
    </Flex>
  );
};
