import React from "react";
import { Modal as BSModal } from "react-bootstrap";
import { COLORS } from "../../../assets/colors";

type Props = {
  children: React.ReactNode;
  show: boolean;
  onHide: () => void;
  keyboard?: boolean;
  size?: "sm" | "lg" | "xl";
  fullscreen?:
    | "sm-down"
    | "md-down"
    | "lg-down"
    | "xl-down"
    | "xxl-down"
    | "xxl-up";
  style?: React.CSSProperties;
};

export const Modal = ({
  children,
  show,
  onHide,
  keyboard,
  size,
  fullscreen,
  style,
}: Props) => {
  return (
    <BSModal
      show={show}
      onHide={onHide}
      keyboard={keyboard}
      size={size}
      fullscreen={fullscreen}
      style={{
        color: COLORS.FontGray,
        borderRadius: "8px",
        ...style,
      }}
    >
      {children}
    </BSModal>
  );
};
