import React, { useEffect, useState } from "react";
import MainContainer from "../main-menu/MainContainer";
import { Flex } from "../elements/flex/Flex";
import { useApiAccessRulesData } from "../../context/accessRuleContext";
import { Text } from "../elements/text/Text";
import { MainButton } from "../elements/buttons/MainButton";
import { SecondaryButton } from "../elements/buttons/SecondaryButton";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { SearchInput } from "../elements/input/SearchInput";
import { AccessRuleFormModal } from "./AccessRuleFormModal";
import { CircularProgress } from "@mui/material";
import { EmptyContent } from "../shared-components/EmptyContent";
import { AccessRuleCard } from "./AccessRuleCard";
import { useIsMobile } from "../../utils/hooks";
import { AccessRule } from "../../types/AccessRule";
import { Link } from "react-router-dom";

type Props = {};

export const AccessRulesList = (props: Props) => {
  const isMobile = useIsMobile();
  const { data: accessRules, isFetching: isFetchingAccessRules } =
    useApiAccessRulesData();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredAccessRules, setFilteredAccessRules] = useState<AccessRule[]>(
    []
  );

  useEffect(() => {
    setFilteredAccessRules(accessRules || []);
  }, [accessRules]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (!value) {
      setFilteredAccessRules(accessRules || []);
      return;
    }
    const filtered = accessRules?.filter((accessRule) =>
      accessRule.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredAccessRules(filtered || []);
  };

  return (
    <MainContainer>
      <Flex
        column
        gap="24px"
        style={{
          minHeight: "100vh",
        }}
      >
        <Flex w100 align="center" gap="24px" justify="between">
          <Text variant={isMobile ? "h4" : "h2"}>Access Rules</Text>
          <Flex gap="8px">
            <SecondaryButton>
              <Link
                to="/activity-logs"
                style={{
                  textDecoration: "none", // Remove underline
                  color: "inherit", // Use the default text color
                  fontSize: "inherit", // Use the default font size
                  fontWeight: "inherit", // Use the default font weight
                }}
              >
                Activity Logs
              </Link>
            </SecondaryButton>
            <MainButton onClick={() => setShowCreateModal(true)}>
              <Flex align="center" gap="8px">
                <MdFormatListBulletedAdd />
                <Text variant="body1">Create New</Text>
              </Flex>
            </MainButton>
          </Flex>
        </Flex>
        <SearchInput
          style={{
            maxWidth: "300px",
          }}
          placeholder="Search access rules"
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
        />
        {filteredAccessRules?.length === 0 && (
          <Flex w100 h100 align="center" justify="center">
            {isFetchingAccessRules ? (
              <CircularProgress />
            ) : (
              <EmptyContent
                title="No access rules found"
                subTitle={
                  accessRules?.length === 0
                    ? "Create a new access rule to get started"
                    : "No access rules found with the search criteria"
                }
              />
            )}
          </Flex>
        )}
        <Flex column gap="16px">
          {filteredAccessRules?.map((accessRule) => (
            <AccessRuleCard key={accessRule.id} accessRule={accessRule} />
          ))}
        </Flex>
      </Flex>
      <AccessRuleFormModal
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
      />
    </MainContainer>
  );
};
