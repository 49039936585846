import { useState } from "react";
import MainContainer from "../main-menu/MainContainer";
import { Flex } from "../elements/flex/Flex";
import { useApiAccessRulesData } from "../../context/accessRuleContext";
import { Text } from "../elements/text/Text";
import { Autocomplete, TextField } from "@mui/material";
import { useIsMobile } from "../../utils/hooks";
import { useApiActivityLogsFiltered } from "../../context/activityLogContext";
import { ActivityLog } from "../../types/ActivityLog";
import { useSearchParams } from "react-router-dom";
import { ActivityLogCard } from "./ActivityLogCard";
import { LiaTimesSolid } from "react-icons/lia";

export const ActivityLogsList = () => {
  let [searchParams] = useSearchParams();
  const isMobile = useIsMobile();
  const { data: accessRules } = useApiAccessRulesData();
  const [filters, setActivityLogsFilters] = useState({});
  const { data: activityLogs } = useApiActivityLogsFiltered(filters);
  console.log(activityLogs);
  const findAccessRule = (accessRuleId: string) =>
    (accessRules ?? []).find((accessRule) => accessRule.id === accessRuleId) ||
    null;
  const ruleNamesOptions = activityLogs?.length
    ? Array.from(
        new Set(
          activityLogs.map((activityLog: ActivityLog) => activityLog.rule_name)
        )
      ).map((rule_name) => ({
        label: rule_name,
        value: rule_name,
      }))
    : [];

  const rulePerformerOptions = activityLogs?.length
    ? Array.from(
        new Set(
          activityLogs.map(
            (activityLog: ActivityLog) => activityLog.performed_by
          )
        )
      ).map((performed_by) => ({
        label: performed_by,
        value: performed_by,
      }))
    : [];

  const ruleActionOptions = [
    { label: "Create", value: "create" },
    { label: "Delete", value: "delete" },
    { label: "Update", value: "update" },
  ];

  return (
    <MainContainer>
      <Flex
        column
        gap="24px"
        style={{
          minHeight: "100vh",
        }}
      >
        <Flex w100 align="center" gap="24px" justify="between">
          <Text variant={isMobile ? "h4" : "h2"}>Activity Logs</Text>
          <LiaTimesSolid
            size={24}
            className="pointer"
            onClick={() => window.history.back()}
          />
        </Flex>
        <Flex
          w100
          align={isMobile ? "start" : "center"}
          gap="6px"
          justify="between"
          column={isMobile}
        >
          <Autocomplete
            options={ruleNamesOptions}
            id="select-type-filter-alerts"
            disableCloseOnSelect
            defaultValue={ruleNamesOptions.find(
              (o) => o.value === searchParams.get("rule_name")
            )}
            onChange={(e, option) =>
              setActivityLogsFilters({
                ...filters,
                rule_name: option?.value || "",
              })
            }
            renderInput={(params) => (
              <TextField {...params} label="Rule Name" variant="standard" />
            )}
            style={{ width: "100%" }}
          />
          <Autocomplete
            options={rulePerformerOptions}
            id="select-type-filter-alerts"
            disableCloseOnSelect
            defaultValue={rulePerformerOptions.find(
              (o) => o.value === searchParams.get("performer")
            )}
            onChange={(e, option) =>
              setActivityLogsFilters({
                ...filters,
                performer: option?.value || "",
              })
            }
            renderInput={(params) => (
              <TextField {...params} label="Performed By" variant="standard" />
            )}
            style={{ width: "100%" }}
          />
          <Autocomplete
            options={ruleActionOptions}
            id="select-type-filter-alerts"
            disableCloseOnSelect
            defaultValue={ruleActionOptions.find(
              (o) => o.value === searchParams.get("action")
            )}
            onChange={(e, option) =>
              setActivityLogsFilters({
                ...filters,
                action: option?.value || "",
              })
            }
            renderInput={(params) => (
              <TextField {...params} label="Action" variant="standard" />
            )}
            style={{ width: "100%" }}
          />
        </Flex>

        <Flex w100 column gap="16px">
          {activityLogs?.map((activityLog: ActivityLog) => (
            <ActivityLogCard
              key={activityLog._id}
              activityLog={activityLog}
              accessRule={
                activityLog._access_rule
                  ? findAccessRule(activityLog._access_rule)
                  : null
              }
            />
          ))}
        </Flex>
      </Flex>
    </MainContainer>
  );
};
