import React from "react";
import { Text } from "../elements/text/Text";
import { Select } from "../elements/select/Select";
import { Flex } from "../elements/flex/Flex";

type TimezoneSelectProps = {
  selectedTimezone: string;
  onChange: (tz: string) => void;
  labelWidth?: string;
  inputWidth?: string;
};

const defaultLabelWidth = "150px";
const defaultInputWidth = "300px";

const timezones = Intl.supportedValuesOf("timeZone");
const timezonesOptions = [
  { label: "UTC", value: "UTC" },
  ...timezones.map((tz) => ({ label: tz, value: tz })),
];

export const TimezoneSelect: React.FC<TimezoneSelectProps> = ({
  selectedTimezone,
  onChange,
  labelWidth = defaultLabelWidth,
  inputWidth = defaultInputWidth,
}) => {
  return (
    <Flex align="center" gap="16px">
      <Text variant="body1" style={{ width: labelWidth }}>
        Timezone
      </Text>
      <Select
        value={timezonesOptions.find((tz) => tz.value === selectedTimezone)}
        options={timezonesOptions}
        style={{ width: inputWidth }}
        onChange={(option) => onChange(option?.value || selectedTimezone)}
      />
    </Flex>
  );
};
