import React from "react";
import { Modal } from "../elements/modals/Modal";
import { Flex } from "../elements/flex/Flex";
import { Text } from "../elements/text/Text";
import { SecondaryButton } from "../elements/buttons/SecondaryButton";
import { LiaTimesSolid } from "react-icons/lia";
import { Illustration } from "../elements/illustration/Illustration";

type Props = {
  showModal: boolean;
  title?: string;
  subTitle?: string;
  onConfirm: () => void;
  onCancel: () => void;
  inProgress?: boolean;
};

export const DeleteModalConfirmation = ({
  showModal,
  title,
  subTitle,
  onConfirm,
  onCancel,
  inProgress,
}: Props) => {
  return (
    <Modal onHide={onCancel} show={showModal} size="sm">
      <Flex
        column
        padding="16px"
        gap="16px"
        align="center"
        style={{
          position: "relative",
        }}
      >
        <LiaTimesSolid
          size={16}
          className="pointer"
          onClick={onCancel}
          style={{
            position: "absolute",
            top: 16,
            right: 16,
          }}
        />

        <Text className="text-center" variant="h5">
          {title || "Are you sure?"}
        </Text>
        <Illustration name="delete" style={{ width: "180px" }} />
        {subTitle && (
          <Text className="text-center" variant="body1">
            {subTitle}
          </Text>
        )}
        <Flex justify="between" w100 gap="8px">
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <SecondaryButton
            variant="danger"
            onClick={onConfirm}
            inProgress={inProgress}
          >
            Delete
          </SecondaryButton>
        </Flex>
      </Flex>
    </Modal>
  );
};
