// this File sould return a Icon based on the result of the match
import React from "react";
import {
  FaDoorClosed,
  FaDoorOpen,
  FaExclamationTriangle,
  FaLock,
} from "react-icons/fa";
import {
  MdDisabledVisible,
  MdOutlineBlock,
  MdOutlineSkipNext,
} from "react-icons/md";
import { COLORS } from "../../assets/colors";
import { TbDoorOff } from "react-icons/tb";
import { TagEventResult } from "../../types/TagEvent";

export const TagsResultIcon: React.FC<{ result: TagEventResult }> = ({
  result,
}) => {
  switch (result) {
    case "ok":
      return (
        <FaDoorOpen
          width={16}
          height={16}
          color={COLORS.FontGray}
          style={{ position: "relative", top: "-2px" }}
        />
      );
    case "error":
      return (
        <FaExclamationTriangle
          width={16}
          height={16}
          color={COLORS.FontGray}
          style={{ position: "relative", top: "-2px" }}
        />
      );
    case "skipped":
    case "skipped_reoccurred":
    case "skipped_disabled":
    case "skipped_type":
    case "skipped_android":
      return (
        <MdOutlineSkipNext width={16} height={16} color={COLORS.FontGray} />
      );
    case "fraud":
      return (
        <FaLock
          width={16}
          height={16}
          color={COLORS.FontGray}
          style={{ position: "relative", top: "-2px" }}
        />
      );
    case "passback":
      return <FaDoorClosed width={16} height={16} color={COLORS.FontGray} />;
    case "ok_no_door":
      return (
        <TbDoorOff
          width={16}
          height={16}
          color={COLORS.FontGray}
          style={{ position: "relative", top: "-2px" }}
        />
      );
    case "unauthorized":
      return <MdOutlineBlock width={16} height={16} color={COLORS.FontGray} />;
    case "mismatch":
      return (
        <MdDisabledVisible width={16} height={16} color={COLORS.FontGray} />
      );
  }
};
