import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Flex } from "../elements/flex/Flex";
import { post } from "../../faceit-api/FaceitApi";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import { SecondaryButton } from "../elements/buttons/SecondaryButton";
import { DataContext } from "../../App";
import { Text } from "../elements/text/Text";
import { useApiTenantsData } from "../../context/usersContext";
import { useIsMobile } from "../../utils/hooks";
import { SearchInput } from "../elements/input/SearchInput";
import { Tenant } from "../../types/Tenant";

export const TenantsTable = () => {
  const { userInfo } = useContext(DataContext);
  const { data: tenants, isFetching } = useApiTenantsData();
  const [search, setSearch] = useState("");
  const [tenantsFiltered, setTenantsFiltered] = useState<Tenant[]>(
    tenants || []
  );
  const isMobile = useIsMobile();

  useEffect(() => {
    setTenantsFiltered(tenants || []);
  }, [tenants]);

  const moveTenant = (tenantId: number) => {
    post(`users/move_tenant`, {
      tenant_id: tenantId,
    }).then((resp) => {
      if (resp.status === 1) {
        showSuccessDialog(
          `The transition was completed successfully, you are now part of the tenant group: ${
            tenants?.find((t) => t.id === tenantId)?.name
          }`
        ).then(() => window.location.reload());
      } else {
        showErrorDialog(resp.message || "Failed to create tenant");
      }
    });
  };

  return (
    <Flex
      column
      gap="12px"
      style={{
        maxHeight: "calc(50vh - 100px)",
      }}
    >
      <hr />
      <Flex align="center" gap="24px" column={isMobile}>
        <Text variant={isMobile ? "h5" : "h4"}>Tenants</Text>
        <SearchInput
          placeholder="Search tenants"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setTenantsFiltered(
              tenants?.filter((t) =>
                t.name.toLowerCase().includes(e.target.value.toLowerCase())
              ) || []
            );
          }}
        />
      </Flex>
      <DataTable
        data={tenantsFiltered || []}
        columns={[
          {
            name: "ID",
            selector: (row) => row.id.toString(),
            maxWidth: "50px",
            hide: 700,
          },
          {
            name: "Name",
            selector: (row) => row.name,
            cell: (row) => (
              <Text truncate variant="body1" title={row.name}>
                {row.name}
              </Text>
            ),
          },
          {
            name: "Actions",
            minWidth: "180px",
            cell: (row) => (
              <SecondaryButton
                onClick={() => moveTenant(row.id)}
                disabled={row.id === userInfo?.tenant_id}
              >
                GO TO TENANT
              </SecondaryButton>
            ),
          },
        ]}
        theme="solarized"
        noDataComponent={isFetching ? "Loading tenants..." : "No tenants found"}
        fixedHeader
      />
    </Flex>
  );
};
