import React from "react";
import { Flex } from "../elements/flex/Flex";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";
import { Camera, IntercomSettings } from "../../types/Camera";

type Props = {
  camera: Camera;
  setCamera: React.Dispatch<React.SetStateAction<Camera>>;
};

const defaultOverlayConfig: IntercomSettings = {
  enable_video_call: false,
};

export const CameraSettingsIntercom: React.FC<Props> = ({
  camera,
  setCamera,
}: Props) => {
  const updateSettings = (settingKey: string, value: any) => {
    setCamera({
      ...camera,
      intercom_setting: {
        ...(camera.intercom_setting || defaultOverlayConfig),
        [settingKey]: value,
      },
    });
  };

  return (
    <Flex column gap="24px" className="py-3">
      <Flex align="center" gap="8px">
        <div
          className="text-truncate"
          title="Enable Branding"
          style={{
            width: "155px",
          }}
        >
          Enable Video Call
        </div>
        <SwitchCheckbox
          checked={camera?.intercom_setting?.enable_video_call || false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateSettings("enable_video_call", e.target.checked)
          }
        />
      </Flex>
    </Flex>
  );
};
