import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  FaChevronRight,
  FaChartLine,
  FaVideo,
  FaUsers,
  FaCrosshairs,
  FaCogs,
  FaChevronLeft,
} from "react-icons/fa";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import "./Sidebar.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { post } from "../../faceit-api/FaceitApi";
import { DataContext } from "../../App";
import InactiveBanner from "../elements/banners/InactiveBanner";
import WarningBanner from "../elements/banners/WarningBanner";
import { useApiHealthCheck } from "../../context/healthCheckContext";

export const logout = () => {
  post("logout").then((resp) => {
    console.log(resp);
    cookie.remove("jwt_token", { path: "/" });
    cookie.remove("jwt_token", { path: "/fiq" });
    cookie.remove("jwt_token", { path: "*" });
    window.location.reload();
  });
};

function Sidebar(props) {
  const { activeComponent, closeMenu } = props;
  const { sidebarCollapse, setSidebarCollapse, userInfo, isMobile } =
    useContext(DataContext);
  const { data: healthCheck } = useApiHealthCheck();
  // const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (!!isMobile && !!setSidebarCollapse && isMobile) {
      setSidebarCollapse(false);
    }
  }, [isMobile, setSidebarCollapse]);

  return (
    <div>
      <div
        className="hide-scrollbar"
        style={{
          height: "100vh",
          position: "absolute",
        }}
      >
        <ProSidebar
          collapsed={sidebarCollapse}
          width={sidebarCollapse ? "60px" : "270px"}
        >
          {!isMobile && (
            <Menu className="h-25 mt-5 pt-3" iconShape="square">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>{`${
                    sidebarCollapse ? "Open" : "Close"
                  } side menu`}</Tooltip>
                }
              >
                <MenuItem
                  icon={
                    sidebarCollapse ? <FaChevronRight /> : <FaChevronLeft />
                  }
                  onClick={() => setSidebarCollapse(!sidebarCollapse)}
                ></MenuItem>
              </OverlayTrigger>
            </Menu>
          )}

          <Menu className="h-100" iconShape="square">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Statistical segmentation, production of insights, reports
                </Tooltip>
              }
            >
              <MenuItem
                className={`my-4 ${
                  activeComponent === "dashboard" ? "sr-menu-item-selected" : ""
                }`}
                icon={<FaChartLine />}
                onClick={() => (isMobile ? closeMenu() : {})}
              >
                Dashboard
                <Link to="/dashboard" />
              </MenuItem>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Setting up cameras</Tooltip>}
            >
              <MenuItem
                className={`my-4 ${
                  activeComponent === "cameras" ? "sr-menu-item-selected" : ""
                }`}
                icon={<FaVideo />}
                onClick={() => (isMobile ? closeMenu() : {})}
              >
                Cameras <Link to="/cameras" />
              </MenuItem>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Manage target groups and people for identification
                </Tooltip>
              }
            >
              <MenuItem
                className={`my-4 ${
                  activeComponent === "people" ? "sr-menu-item-selected" : ""
                }`}
                icon={<FaUsers />}
                onClick={() => (isMobile ? closeMenu() : {})}
              >
                People <Link to="/people" />
              </MenuItem>
            </OverlayTrigger>

            <SubMenu
              className={`my-4 ${
                activeComponent === "detections" ? "sr-menu-item-selected" : ""
              }`}
              title="Detections"
              icon={<FaCrosshairs />}
            >
              <MenuItem onClick={() => (isMobile ? closeMenu() : {})}>
                Live view <Link to="/live" />
              </MenuItem>

              <MenuItem onClick={() => (isMobile ? closeMenu() : {})}>
                Faces events <Link to="/faces" />
              </MenuItem>

              <MenuItem onClick={() => (isMobile ? closeMenu() : {})}>
                Tags events <Link to="/tags" />
              </MenuItem>
            </SubMenu>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {healthCheck.is_system_active === false && isMobile ? (
                <div
                  style={{
                    height: "30px",
                    fontSize: "12px",
                  }}
                >
                  <InactiveBanner style={{}}>
                    {" "}
                    System is inactive{" "}
                  </InactiveBanner>
                </div>
              ) : (
                ""
              )}
              {healthCheck.is_online === false && isMobile ? (
                <div
                  style={{
                    height: "30px",
                    fontSize: "12px",
                  }}
                >
                  <WarningBanner> Internet is offline </WarningBanner>
                </div>
              ) : (
                ""
              )}
            </div>
          </Menu>
          <Menu className="h-25" iconShape="square">
            <SubMenu
              className={`my-4 ${
                activeComponent === "settings" ? "sr-menu-item-selected" : ""
              }`}
              title="Settings"
              icon={<FaCogs />}
            >
              <MenuItem onClick={() => (isMobile ? closeMenu() : {})}>
                Settings
                <Link to="/settings" />
              </MenuItem>
              <MenuItem onClick={() => (isMobile ? closeMenu() : {})}>
                Access Rules
                <Link to="/access-rules" />
              </MenuItem>
              {(userInfo?.is_staff || userInfo?.is_integrator_admin) && (
                <MenuItem onClick={() => (isMobile ? closeMenu() : {})}>
                  {" "}
                  <Link to="/system" />
                  System
                </MenuItem>
              )}
              {(userInfo?.is_staff || userInfo?.is_integrator_admin) && (
                <MenuItem onClick={() => (isMobile ? closeMenu() : {})}>
                  {" "}
                  <Link to="/accounts" />
                  Accounts
                </MenuItem>
              )}
              {(userInfo?.is_staff || userInfo?.is_integrator_admin) && (
                <MenuItem onClick={() => (isMobile ? closeMenu() : {})}>
                  {" "}
                  <Link
                    to="/admin-panel"
                    onClick={() =>
                      window.open(
                        "https://faceit.spotreality.com/admin-panel",
                        "_blank"
                      )
                    }
                  />
                  Admin panel
                </MenuItem>
              )}
            </SubMenu>
          </Menu>
        </ProSidebar>
      </div>
    </div>
  );
}

export default Sidebar;
