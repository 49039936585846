import React, { useContext, useState } from "react";
import MainContainer from "../main-menu/MainContainer";
import DataTable, { createTheme } from "react-data-table-component";
import { COLORS } from "../../assets/colors";
import { deleteItem, IMAGE_BASE_PATH, post } from "../../faceit-api/FaceitApi";
import { FaCheck, FaTimes, FaTrash, FaUserEdit } from "react-icons/fa";
import IconUser from "../../assets/icons/user.svg";
// @ts-ignore
import Swal from "sweetalert2/src/sweetalert2.js";
import ReactDOMServer from "react-dom/server";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import { DataContext } from "../../App";
import { MdGroupAdd } from "react-icons/md";
import { useIsMobile } from "../../utils/hooks";
import { AddAccountModal } from "./AddAccountModal";
import { EditAccountModal } from "./EditAccount";
import {
  useApiIntegratorsData,
  useApiUsersData,
} from "../../context/usersContext";
import { User } from "../../types/User";
import { Modal } from "../elements/modals/Modal";
import { Text } from "../elements/text/Text";
import { TenantsTable } from "./TenantsTable";
import { Flex } from "../elements/flex/Flex";

export const Accounts = () => {
  const isMobile = useIsMobile();
  const { data: users, isFetching: isFetchingUsers } = useApiUsersData();
  const { data: integrators, isFetching: isFetchingIntegrators } =
    useApiIntegratorsData();
  const { userInfo } = useContext(DataContext);
  const [showAddUserModal, setShowAddUser] = useState(false);
  const [showEditUserModal, setShowEditUser] = useState(false);
  const [user, setUserToEdit] = useState({});

  createTheme(
    "solarized",
    {
      text: {
        primary: COLORS.Gray50,
        secondary: COLORS.Gray100,
      },
      background: {
        default: COLORS.BgBlack,
      },
      context: {
        background: "#cb4b16",
        text: COLORS.Gray50,
      },
      divider: {
        default: COLORS.Gray50,
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: COLORS.BgBlack,
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  const addTenant = () => {
    Swal.fire({
      title: "Enter tenant name",
      showCancelButton: true,
      input: "text",
      confirmButtonColor: COLORS.primary,
      cancelButtonColor: "#afa7a7",
      confirmButtonText: "Create",
      preConfirm: (input?: string) => {
        if (!input) {
          Swal.showValidationMessage("Name cannot be empty");
          return false;
        } else {
          return input;
        }
      },
    }).then((result: any) => {
      if (result.value) {
        post(`tenants`, {
          name: result.value,
        }).then((resp) => {
          if (resp.status === 1) {
            showSuccessDialog("Tenant has been created successfully").then(() =>
              window.location.reload()
            );
          } else {
            showErrorDialog(resp.message || "Failed to create tenant");
          }
        });
      }
    });
  };

  function openAddUserModal() {
    setShowAddUser(true);
  }

  function closeAddUserModal() {
    setShowAddUser(false);
  }

  function openEditUserModal(row: User) {
    setShowEditUser(true);
    setUserToEdit(row);
  }
  function closeEditUserModal() {
    setShowEditUser(false);
  }

  function deleteAccount(user: User) {
    Swal.fire({
      title: `Are you sure you want to delete ${user.username}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: COLORS.primary,
      cancelButtonColor: "#afa7a7",
      confirmButtonText: "Yes, delete him!",
      html: ReactDOMServer.renderToString(
        <div className="">
          <img
            style={{
              height: "120px",
              width: "120px",
              borderRadius: "8px",
            }}
            alt="avatar"
            src={user.avatar ? `${IMAGE_BASE_PATH}/${user.avatar}` : IconUser}
          />
          <h6 className="mt-2 font-weight-bold">
            You won't be able to revert this!
          </h6>
        </div>
      ),
    }).then((result: any) => {
      if (result.value) {
        deleteItem(`users/${user.id}`).then((resp) => {
          if (resp.status === 1) {
            showSuccessDialog("User has been deleted successfully").then(() =>
              window.location.reload()
            );
          } else {
            showErrorDialog(resp.message || "Failed to delete user");
          }
        });
      }
    });
  }

  // const integratorsList = Array.from(new Set(integrators?.map((i) => i.integrator))) || [];
  const columns = [
    {
      name: "Avatar",
      id: "avatar",
      sortable: false,
      cell: (row: User) => (
        <div className="py-3">
          <img
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
            }}
            src={row.avatar ? `${IMAGE_BASE_PATH}/${row.avatar}` : IconUser}
            alt="user-avatar"
          />
        </div>
      ),
    },
    {
      name: "Username",
      id: "username",
      selector: (row: User) => row.username || "N/A",
      sortable: false,
    },
    {
      name: "Name",
      id: "name",
      selector: (row: User) => `${row.first_name} ${row.last_name}`,
      sortable: false,
    },
    {
      name: "Email",
      id: "email",
      selector: (row: User) => row.email || "N/A",
      sortable: false,
    },
    {
      name: "Integrator",
      id: "integrator",
      selector: (row: User) => row.integrator || "N/A",
      sortable: false,
      cell: (row: User) => (
        <div>
          {row.integrator ? (
            <span className="text">{row.integrator}</span>
          ) : (
            <span className="text-muted"></span>
          )}
        </div>
      ),
    },
    {
      name: "Is Admin", // is_staff or is integrator admin
      id: "admin",
      sortable: false,
      cell: (row: User) => (
        <div>
          {row.is_staff || row.is_integrator_admin ? (
            <FaCheck className="fs-5" color={COLORS.PrimaryLight} />
          ) : (
            <FaTimes className="fs-5" color={COLORS.red} />
          )}
        </div>
      ),
    },
    {
      name: "Last login",
      id: "last_login",
      sortable: false,
      cell: (row: User) => (
        <div>
          <span
            title={new Date(row.last_login || "").toLocaleString()}
            className="fw-bold"
          >
            {new Date(row.last_login || "").toLocaleDateString()}
          </span>
        </div>
      ),
    },
    {
      name: "Created",
      id: "created",
      sortable: false,
      cell: (row: User) => (
        <div>
          <span
            title={new Date(row.date_joined || "").toLocaleString()}
            className="fw-bold"
          >
            {new Date(row.date_joined || "").toLocaleDateString()}
          </span>
        </div>
      ),
    },
    {
      name: "Actions",
      id: "actions",
      sortable: false,
      cell: (row: User) =>
        row.is_superuser ? (
          <></>
        ) : (
          <div className="d-flex align-items-center">
            {!isFetchingIntegrators && (
              <FaUserEdit
                color={COLORS.PrimaryLight}
                className="pointer fs-5 me-4"
                onClick={() => {
                  openEditUserModal(row);
                }}
              />
            )}
            <FaTrash
              color={COLORS.red}
              className="pointer fs-6 "
              onClick={() => deleteAccount(row)}
            />
          </div>
        ),
    },
  ];

  return (
    <MainContainer>
      <div
        className={`d-flex justify-content-between gap-2 ${
          isMobile ? "flex-column align-items-start " : "align-items-center "
        }`}
      >
        <Text variant={isMobile ? "h4" : "h2"}>Accounts</Text>
        <div
          className={`d-flex justify-content-between gap-2 ${
            isMobile ? "flex-column" : ""
          }`}
        >
          <div className="d-flex gap-4">
            {(userInfo?.is_staff || userInfo?.is_integrator_admin) && (
              <div>
                <button
                  className="sr-btn d-flex align-items-center"
                  onClick={openAddUserModal}
                  style={{ width: "fit-content" }}
                  disabled={isFetchingIntegrators}
                >
                  <MdGroupAdd className="me-2" />
                  Add User
                </button>
                <AddAccountModal
                  show={showAddUserModal}
                  onHide={closeAddUserModal}
                  integratorsList={integrators}
                  integratorAdmin={userInfo?.is_integrator_admin}
                />
              </div>
            )}
            {userInfo?.is_superuser && (
              <button
                className="sr-btn d-flex align-items-center"
                onClick={addTenant}
                style={{ width: "fit-content" }}
              >
                <MdGroupAdd className="me-2" />
                Add tenant
              </button>
            )}
          </div>
        </div>

        <Modal
          show={showEditUserModal}
          onHide={() => closeEditUserModal()}
          keyboard={false}
          size="sm"
          fullscreen={"md-down"}
        >
          <EditAccountModal
            close={() => closeEditUserModal()}
            userToEdit={user}
            integratorsList={integrators}
            integratorAdmin={userInfo?.is_integrator_admin}
          />
        </Modal>
      </div>
      {userInfo?.is_superuser && <TenantsTable />}
      <Flex column gap="12px">
        <hr />
        <Text variant={isMobile ? "h5" : "h4"}>Users</Text>
        <DataTable
          key={users?.length}
          columns={columns}
          data={users || []}
          pagination
          highlightOnHover
          paginationTotalRows={users?.length || 0}
          paginationRowsPerPageOptions={[25, 50, 100]}
          noDataComponent={
            isFetchingUsers ? "Loading users..." : "No users found"
          }
          theme="solarized"
        />
      </Flex>
    </MainContainer>
  );
};
