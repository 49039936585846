import React, { useState } from "react";
import { COLORS } from "../../assets/colors";
import StreamPlayer from "./StreamPlayer";
import IconCog from "../../assets/icons/cog.svg";
import IconVideo from "../../assets/icons/video.svg";
import IconEye from "../../assets/icons/eye.svg";

import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import CameraSettingsModal from "./CameraSettingsModal";
import { MdTabletAndroid } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import { Camera as CameraType } from "../../types/Camera";
import { useIsMobile } from "../../utils/hooks";
import { Flex } from "../elements/flex/Flex";
import { Illustration } from "../elements/illustration/Illustration";
import { Text } from "../elements/text/Text";

type CameraProps = {
  camera: CameraType;
};

function CameraComponent(props: CameraProps) {
  const { camera } = props;
  const [showCameraSettingsModal, setShowCameraSettingsModal] = useState(false);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <div className={`h-100  m-2 ${isMobile ? "w-100" : ""}`}>
      <Modal
        show={showCameraSettingsModal}
        onHide={() => setShowCameraSettingsModal(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        fullscreen={"md-down"}
      >
        <CameraSettingsModal
          close={() => setShowCameraSettingsModal(false)}
          camera={camera}
        />
      </Modal>
      <div
        className=" w-100"
        style={{
          background: COLORS.Gray700,
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
          border: "1px solid #404040",
          boxSizing: "border-box",
          borderRadius: "8px",
        }}
      >
        <div className="h-100 w-100 pb-2">
          <div className=" d-flex justify-content-between ">
            <div
              className="d-flex w-75 mt-2 align-items-center"
              style={{
                fontSize: "24px",
                height: "44px",
              }}
            >
              {camera.is_android ? (
                <MdTabletAndroid className="mx-2" />
              ) : (
                <img
                  src={IconVideo}
                  height={"20"}
                  width={"20"}
                  alt="video-icon"
                  className="mx-2"
                />
              )}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{camera.name}</Tooltip>}
              >
                <span className="text-truncate">{camera.name}</span>
              </OverlayTrigger>
            </div>
            <div className="w-50 mt-2 text-end">
              <img
                style={{
                  cursor: "pointer",
                }}
                id={`camera-settings-btn-${camera.id}`}
                onClick={() => setShowCameraSettingsModal(true)}
                src={IconCog}
                height={"20"}
                width={"20"}
                alt="cog-icon"
                className="me-2"
              />
            </div>
          </div>
          <div className="d-flex justify-content-center  col-12 align-items-center px-3 pb-2">
            {camera.is_android ? (
              <Flex
                style={{
                  height: "240px",
                  width: isMobile ? "100%" : "350px",
                }}
              >
                {camera.android_screen ? (
                  <img
                    src={`${IMAGE_BASE_PATH}${camera.android_screen}`}
                    alt="android-screen"
                    style={{
                      height: "100%",
                      width: "100%",
                      background: COLORS.Black,
                      borderRadius: "4px",
                    }}
                  />
                ) : (
                  <Flex
                    column
                    align="center"
                    justify="center"
                    gap="6px"
                    padding="4px"
                    style={{
                      background: COLORS.Black,
                      borderRadius: "4px",
                      width: isMobile ? "100%" : "340px",
                    }}
                  >
                    <Illustration name="empty" height="100px" />
                    <Text variant="h6" align="center">
                      Device screenshot will be displayed here
                    </Text>
                  </Flex>
                )}
              </Flex>
            ) : (
              <StreamPlayer
                width={isMobile ? "100%" : "350px"}
                height="240px"
                camera={camera}
              />
            )}
          </div>
          <div className="col-6 mx-auto mb-2">
            <button
              className="sr-btn d-flex align-items-center  justify-content-center"
              onClick={() => navigate(`/live?cameraId=${camera.id}`)}
            >
              <img
                src={IconEye}
                height="18px"
                width={"18px"}
                alt="eye"
                className="me-2"
              />
              View
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CameraComponent;
