// SearchInput.tsx
import React, { InputHTMLAttributes, forwardRef } from "react";
import { Input, InputWrapper, SearchIcon } from "./Input";

// Define the props interface, extending standard input props
interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  showIcon?: boolean; // Optional prop to show/hide the search icon
}

// Forward ref to allow parent components to access the input element
export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ placeholder = "Search...", showIcon = true, ...props }, ref) => {
    return (
      <InputWrapper>
        {showIcon && <SearchIcon data-testid="search-icon" />}
        <Input
          placeholder={placeholder}
          hasIcon={showIcon}
          ref={ref}
          {...props}
        />
      </InputWrapper>
    );
  }
);

// Set a display name for better debugging and React DevTools
SearchInput.displayName = "SearchInput";
