export type TagEventResult =
  | "ok"
  | "error"
  | "skipped"
  | "fraud"
  | "passback"
  | "ok_no_door"
  | "skipped_reoccurred"
  | "skipped_disabled"
  | "unauthorized"
  | "skipped_type"
  | "skipped_android"
  | "mismatch";

export type TagEventType =
  | "nothing"
  | "known_tag_id"
  | "unknown_tag_id"
  | "tag_id_match_person"
  | "tag_id_mismatch_person"
  | "tag_id_unknown_face";

export type TagEvent = {
  id: string;
  tag_id: string;
  camera_id: string;
  created: string;
  edge_id: string;
  result: TagEventResult;
  type: TagEventType;
  person_id: string | null;
  image: string;
  sent?: boolean;
};
// add explain dict for TagEventResult
export const TagEventResultExplain: Record<TagEventResult, string> = {
  ok: "Person is allowed to enter, door is opened",
  error: "Error occurred",
  skipped: "Case Skipped",
  passback:
    "The door was not opened for this event as it did not comply with the Anti-Passback Policy",
  ok_no_door: "Person is allowed to enter but door is not connected",
  unauthorized: "Person is not authorized to enter",
  mismatch: "Tag ID mismatch to face",
  fraud: "Fraud detected",
  skipped_reoccurred: "Case Skipped because it reoccurred",
  skipped_disabled: "Case Skipped because it is disabled",
  skipped_type: "Case Skipped because of type",
  skipped_android: "Case Skipped because of android",
};
