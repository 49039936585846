import React from "react";
import { Flex } from "../elements/flex/Flex";
import { Text } from "../elements/text/Text";
import { Illustration } from "../elements/illustration/Illustration";

type Props = {
  title: string;
  subTitle?: string;
  illustrationSize?: string;
};

export const EmptyContent = ({ title, subTitle, illustrationSize }: Props) => {
  return (
    <Flex
      column
      align="center"
      justify="center"
      className="text-center"
      gap="8px"
    >
      <Illustration
        name={"empty"}
        style={{ width: illustrationSize || "120px" }}
      />
      <Text className="text-center" variant="h5">
        {title}
      </Text>
      {subTitle && (
        <Text className="text-center" variant="body1">
          {subTitle}
        </Text>
      )}
    </Flex>
  );
};
