export type TimeRange = {
  from_time: string;
  to_time: string;
};

export type DaySchedule = {
  day_of_week: number;
  time_ranges: TimeRange[];
};

export type AccessRuleType = "one_time" | "cyclical" | "permanent";

export const accessRuleTypeOptions = [
  { value: "one_time", label: "One Time" },
  { value: "cyclical", label: "Cyclical" },
  { value: "permanent", label: "Permanent" },
];

export type AccessRuleUpdate = {
  name?: string;
  person?: string;
  group?: string;
  camera?: string;
  zone?: string;
  rule_type?: AccessRuleType;
  should_notify?: boolean;
  should_access?: boolean;
  one_time_use?: boolean;
  start_date?: string;
  end_date?: string;
  days?: DaySchedule[];
};

export type AccessRule = AccessRuleUpdate & {
  id: string;
  tenant_id: number;
  name: string;
  rule_type: AccessRuleType; // not optional
  created: string;
  updated: string;
};
