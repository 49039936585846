import { useMutation, useQuery, useQueryClient } from "react-query";
import { get, post, put } from "../faceit-api/FaceitApi";
import { bitAfterNow, DEFAULT_STALE_TIME } from "../utils/helper";
import { PEOPLE_MAX_PAGE } from "./personsContext";
import { Camera, CameraEdit } from "../types/Camera";

type CreateCameraProps = {
  cameraData?: CameraEdit;
  onSuccessCallback: (camera: Camera) => void;
  onErrorCallback: (resp: any) => void;
};

type UpdateCameraProps = CreateCameraProps & {
  cameraId: string;
};

async function getCameras(): Promise<Camera[]> {
  return get(
    `cameras?${new URLSearchParams({ page_size: PEOPLE_MAX_PAGE.toString() }).toString()}`
  ).then((resp) => {
    return resp?.results || [];
  });
}

async function updateCamera({ cameraId, cameraData }: UpdateCameraProps) {
  return put(`cameras/${cameraId}`, cameraData);
}

async function createCamera({ cameraData }: CreateCameraProps) {
  return post(`cameras`, cameraData);
}

export const useApiCamerasData = () => {
  return useQuery("getCameras", getCameras, {
    keepPreviousData: true,
    initialData: [],
    initialDataUpdatedAt: bitAfterNow(),
    staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
  });
};

export const useApiUpdateCamera = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCamera, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["getCameras"] }),
  });
};

export const useApiCreateCamera = () => {
  const queryClient = useQueryClient();
  return useMutation(createCamera, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["getCameras"] }),
  });
};
