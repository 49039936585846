import React, { useState } from "react";
import { Flex } from "../elements/flex/Flex";
import { COLORS } from "../../assets/colors";
import { AccessRule } from "../../types/AccessRule";
import { Text } from "../elements/text/Text";
import { SecondaryButton } from "../elements/buttons/SecondaryButton";
import { DeleteModalConfirmation } from "../shared-components/DeleteModalConfirmation";
import { useApiDeleteAccessRule } from "../../context/accessRuleContext";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import { AccessRuleFormModal } from "./AccessRuleFormModal";
import { useIsMobile } from "../../utils/hooks";
import { useApiActivityLogsFiltered } from "../../context/activityLogContext";

type Props = {
  accessRule: AccessRule;
};

export const AccessRuleCard = ({ accessRule }: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const isMobile = useIsMobile();
  const { refetch } = useApiActivityLogsFiltered({});

  const { mutate: deleteAccessRule, isLoading: isDeleting } =
    useApiDeleteAccessRule();

  const handleDelete = () => {
    deleteAccessRule({
      accessRuleId: accessRule.id,
      accessRuleData: {},
      onSuccessCallback: () => {
        setShowDeleteModal(false);
        showSuccessDialog("Access rule deleted successfully");
        refetch();
      },
      onErrorCallback: (error) => {
        console.error(error);
        showErrorDialog("Failed to delete access rule");
      },
    });
  };

  return (
    <Flex
      w100
      justify="between"
      gap="24px"
      style={{
        padding: "16px",
        borderRadius: "8px",
        backgroundColor: COLORS.Gray700,
      }}
    >
      <Flex column>
        <Text
          variant="h5"
          truncate
          title={accessRule.name}
          style={{
            maxWidth: isMobile ? "120px" : "350px",
          }}
        >
          {accessRule.name}
        </Text>
        <Text
          truncate
          title={new Date(accessRule.created || "").toLocaleString()}
          style={{
            maxWidth: isMobile ? "120px" : "350px",
          }}
          variant="caption"
        >
          {new Date(accessRule.created || "").toLocaleString()}
        </Text>
      </Flex>
      <Flex align="center" gap="8px">
        <SecondaryButton onClick={() => setShowEditModal(true)}>
          Edit
        </SecondaryButton>
        <SecondaryButton
          onClick={() => setShowDeleteModal(true)}
          variant="danger"
        >
          Delete
        </SecondaryButton>
      </Flex>
      <DeleteModalConfirmation
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        showModal={showDeleteModal}
        inProgress={isDeleting}
        subTitle={`You are about to delete the access rule "${accessRule.name}". This action cannot be undone.`}
      />
      <AccessRuleFormModal
        setShowModal={setShowEditModal}
        showModal={showEditModal}
        editAccessRule={accessRule}
      />
    </Flex>
  );
};
