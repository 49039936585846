import { get } from "../faceit-api/FaceitApi";
import { useQuery } from "react-query";
import { DEFAULT_STALE_TIME } from "../utils/helper";
import { ActivityLogFilters } from "../types/ActivityLog";

async function getActivityLogs(params: ActivityLogFilters) {
  const stringParams = Object.fromEntries(
    Object.entries(params).map(([key, value]) => [key, value.toString()])
  );
  return get(
    `activity-logs?${new URLSearchParams(stringParams).toString()}`
  ).then((resp) => {
    return resp?.results || [];
  });
}

export const useApiActivityLogsFiltered = (filters: ActivityLogFilters) => {
  const readyToUseFilters: ActivityLogFilters = filters;

  return useQuery(
    ["getActivityLogs", readyToUseFilters],

    () => getActivityLogs(readyToUseFilters),
    {
      keepPreviousData: true,
      staleTime: DEFAULT_STALE_TIME,
      refetchInterval: 300000, // 5 minutes
    }
  );
};
