// SearchInput.styled.tsx
import styled from "styled-components";
import { GrSearch } from "react-icons/gr";
import { COLORS } from "../../../assets/colors";

// Wrapper to hold the input and the icon
export const InputWrapper = styled.div`
  position: relative;
`;

// Styled search icon positioned inside the input
export const SearchIcon = styled(GrSearch)`
  position: absolute;
  left: 12px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  color: ${COLORS.FontGray};
  pointer-events: none; /* Allows clicks to pass through to the input */
  font-size: 14px; /* Adjust size as needed */
`;

// Interface for Input props
interface InputProps {
  hasIcon?: boolean;
}

// Updated Input with left padding to accommodate the search icon
export const Input = styled.input<InputProps>`
  background-color: ${COLORS.Gray800};
  color: ${COLORS.FontGray};
  border: none;
  border-radius: 8px;
  padding: 7px 8px 7px ${({ hasIcon }) => (hasIcon ? "40px" : "8px")};
  outline: none;
  width: 100%;
  box-sizing: border-box; /* Ensure padding doesn't affect overall width */

  &:disabled {
    color: ${COLORS.GROUP.gray};
    cursor: not-allowed;
    background-color: ${COLORS.Gray700}; /* Optional: Different background for disabled state */
  }

  &:focus:enabled,
  &:hover:enabled {
    box-shadow: 1px 1px 10px ${COLORS.ShadowLightGray} !important;
  }

  /* Optional: Transition for smooth hover/focus effects */
  transition:
    box-shadow 0.3s ease,
    background-color 0.3s ease;
`;
