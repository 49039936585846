import { BaseButton, BaseButtonProps } from "./BaseButton";

export const MainButton: React.FC<BaseButtonProps> = ({
  children,
  inProgress,
  disabled,
  ...props
}) => (
  <BaseButton
    inProgress={inProgress}
    disabled={inProgress || disabled}
    {...props}
  >
    {children}
  </BaseButton>
);
