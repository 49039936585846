import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import Select from "react-select";
import { SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { COLORS } from "../../assets/colors";
import { Checkbox } from "../elements/checkbox/Checkbox";
import CustomRangeSlider from "../general/CustomRangeSlider";
import { get, IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import { useApiCamerasData } from "../../context/camerasContext";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";
import { useIsMobile } from "../../utils/hooks";
import CameraFramePlaceHolder from "../../assets/camera-frame-placeholder.png";
import { Camera } from "../../types/Camera";
import { Flex } from "../elements/flex/Flex";
import { Input } from "../elements/input/Input";

type CameraSettingsGeneralProps = {
  camera: Camera;
  setCamera: (camera: Camera) => void;
};

function CameraSettingsGeneral(props: CameraSettingsGeneralProps) {
  const { camera, setCamera } = props;
  const { data: cameras } = useApiCamerasData();
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [frame, setFrame] = useState("");
  const isMobile = useIsMobile();
  const usecaseOptions = [
    { label: "Voluntary", value: "voluntary" },
    { label: "Involuntary", value: "involuntary" },
    { label: "Disable", value: "disable" },
  ];

  useEffect(() => {
    let isMounted = true;
    if (frame || !camera.id || camera.is_android) return;

    get(`cameras/${camera.id}/frame`)
      .then((resp) => {
        if (isMounted && resp.status === 1) {
          setFrame(resp.frame_image);
        }
      })
      .catch(console.error);
    return () => {
      isMounted = false;
    };
  }, [frame, camera.id, camera.is_android]);

  const zonesOptions = Array.from(
    new Set((cameras || []).map((c: Camera) => c.zone))
  ).map((zone) => ({
    label: String(zone),
    value: String(zone),
  }));

  return (
    <Flex
      column
      gap="8px"
      className="py-3"
      style={{
        fontSize: "14px",
      }}
    >
      <Flex
        className="py-2"
        style={{ fontSize: "14px", color: COLORS.Gray100 }}
      >
        Camera ID: {camera.id}
      </Flex>
      {!camera.is_android && (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              {"Format example: rtsp://user:password@ip:port/videoMain"}
            </Tooltip>
          }
        >
          <Flex gap="8px" justify="start" align="center">
            <div
              className="text-truncate fw-bolder"
              title="Connection"
              style={{
                maxWidth: "200px",
                width: "200px",
              }}
            >
              Connection
            </div>{" "}
            <Input
              style={{
                maxWidth: "300px",
              }}
              type="text"
              id="edit-cam-url"
              placeholder="rtsp://user:password@ip:port/videoMain..."
              value={camera.url}
              onChange={(e: { target: { value: string } }) =>
                setCamera({
                  ...camera,
                  url: e.target.value,
                })
              }
            />
          </Flex>
        </OverlayTrigger>
      )}
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {
              "Select a zone for the camera, write a new name to create a new zone"
            }
          </Tooltip>
        }
      >
        <Flex align="center" justify="start" gap="8px">
          <div
            className="text-truncate fw-bolder"
            title="Zone"
            style={{
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Zone
          </div>
          <div
            className="w-100"
            style={{
              maxWidth: "300px",
            }}
          >
            <CreatableSelect
              className="react-select-container w-100"
              classNamePrefix="react-select"
              isClearable
              id="edit-cam-zone"
              placeholder="Select or write new name..."
              options={zonesOptions}
              value={zonesOptions.find((z) => z.value === camera.zone)}
              onChange={(e) => {
                setCamera({
                  ...camera,
                  zone: e ? e.value.toString() : "",
                });
              }}
            />
          </div>
        </Flex>
      </OverlayTrigger>
      {!camera.is_android && (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              Select the type of face recognition usage, or deactivation
            </Tooltip>
          }
        >
          <Flex align="center" justify="start" gap="8px">
            <div
              className="text-truncate fw-bolder"
              title="Face Recognition"
              style={{
                maxWidth: "200px",
                width: "200px",
              }}
            >
              Face Recognition
            </div>
            <div
              className="w-100"
              style={{
                maxWidth: "300px",
              }}
            >
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select..."
                id="edit-cam-usecase"
                value={
                  usecaseOptions.find(
                    (o) => camera && o.value === camera.usecase
                  ) || usecaseOptions[0]
                }
                onChange={(
                  e: SingleValue<{
                    label: string;
                    value: string;
                  }>
                ) => {
                  setCamera({
                    ...camera,
                    ...{
                      usecase: e?.value,
                      face_recognition_enabled: e?.value !== "disable",
                      detector_type:
                        e?.value === "involuntary" ? "insightface" : "openvino",
                    },
                  });
                }}
                options={usecaseOptions}
              />
              {camera.usecase === "voluntary" &&
                parseFloat(camera.detection_threshold) !== 0.9 && (
                  <div
                    style={{
                      display: "fixed",
                      alignItems: "center",
                      fontSize: "10px",
                      color: "gray",
                      paddingTop: "6px",
                    }}
                  >
                    recommended detection threshold: 0.9
                  </div>
                )}

              {camera.usecase === "involuntary" &&
                parseFloat(camera.detection_threshold) !== 0.4 && (
                  <div
                    style={{
                      display: "fixed",
                      alignItems: "center",
                      fontSize: "10px",
                      color: "gray",
                      paddingTop: "6px",
                    }}
                  >
                    recommended detection threshold: 0.4
                  </div>
                )}
            </div>
          </Flex>
        </OverlayTrigger>
      )}

      {(!camera.usecase || camera.usecase === "voluntary") && (
        <Flex align="center" justify="start" gap="8px">
          <div
            className="text-truncate fw-bolder"
            style={{
              maxWidth: "200px",
              width: "200px",
            }}
          ></div>
          <Flex
            align="center"
            gap="2px"
            w100
            style={{
              maxWidth: "300px",
            }}
          >
            <Checkbox
              checked={camera.enable_alignment ?? false}
              onChange={(e: { target: { checked: boolean } }) =>
                setCamera({
                  ...camera,
                  enable_alignment: e.target.checked,
                })
              }
            />

            <div style={{ fontSize: "12px" }}>Double check to verify face</div>
          </Flex>
        </Flex>
      )}
      <Flex align="center" justify="start" gap="8px">
        <div
          className="text-truncate fw-bolder"
          title="Save Videos"
          style={{
            maxWidth: "200px",
            width: "200px",
          }}
        >
          Save Videos
        </div>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Save a short video for any event</Tooltip>}
        >
          <Flex
            align="center"
            gap="2px"
            w100
            style={{
              maxWidth: "300px",
            }}
          >
            <SwitchCheckbox
              checked={camera.save_videos || false}
              onChange={(e) =>
                setCamera({ ...camera, save_videos: e.target.checked })
              }
            />
          </Flex>
        </OverlayTrigger>
      </Flex>
      {camera.save_videos && (
        <Flex align="center" justify="start" gap="8px">
          <div
            className="text-truncate fw-bolder"
            style={{
              maxWidth: "200px",
              width: "200px",
            }}
          ></div>
          <Flex
            align="center"
            gap="2px"
            w100
            style={{
              maxWidth: "300px",
            }}
          >
            <Checkbox
              checked={camera.save_video_only_identify || false}
              onChange={(e: { target: { checked: boolean } }) =>
                setCamera({
                  ...camera,
                  save_video_only_identify: e.target.checked,
                })
              }
            />
            <div style={{ fontSize: "12px" }}>
              Only for identifications events
            </div>
          </Flex>
        </Flex>
      )}

      <Flex align="center" className="mt-2 pb-3">
        <div
          className="col-12"
          style={{
            color: COLORS.PrimaryLight,
            textDecoration: "underline",
            fontSize: "16px",
            cursor: "pointer",
          }}
          id="show-cam-advanced-settings"
          onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
        >
          Advanced Settings{" "}
          {showAdvancedSettings ? (
            <FaChevronDown className="ms-2" />
          ) : (
            <FaChevronRight className="ms-2" />
          )}
        </div>
      </Flex>
      {showAdvancedSettings && (
        <Flex column gap="8px">
          {!camera.is_android && (
            <Flex align="center" justify="start" gap="8px">
              <div
                className="text-truncate fw-bolder"
                title="Age & Gender Estimation"
                style={{
                  maxWidth: "200px",
                  width: "200px",
                }}
              >
                Age & Gender
              </div>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>Predict the age and the gender of the face</Tooltip>
                }
              >
                <div
                  className="w-100"
                  style={{
                    maxWidth: "300px",
                  }}
                >
                  <SwitchCheckbox
                    checked={camera.age_gender_enabled || false}
                    onChange={(e) =>
                      setCamera({
                        ...camera,
                        age_gender_enabled: e.target.checked,
                      })
                    }
                  />
                </div>
              </OverlayTrigger>
            </Flex>
          )}
          <Flex align="center" justify="start" gap="8px">
            <div
              className="text-truncate fw-bolder"
              title="Face detection threshold"
              style={{
                maxWidth: "400px",
                width: "400px",
              }}
            >
              Face detection threshold
            </div>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>The level of accuracy of the face detection</Tooltip>
              }
            >
              <div className="w-100">
                <CustomRangeSlider
                  value={camera.detection_threshold}
                  hideSlider={isMobile}
                  min={0}
                  max={1}
                  onChange={(e: { target: { value: string } }) =>
                    setCamera({
                      ...camera,
                      detection_threshold: e.target.value,
                    })
                  }
                />
              </div>
            </OverlayTrigger>
          </Flex>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {"Ignore detected faces that there width is smaller than: " +
                  camera.face_size_limit}
              </Tooltip>
            }
          >
            <Flex align="center" justify="start" gap="8px">
              <div
                className="text-truncate fw-bolder"
                title="Ignore face smaller than"
                style={{
                  maxWidth: "400px",
                  width: "400px",
                }}
              >
                Ignore face smaller than
              </div>
              <Flex w100>
                <Input
                  className="sr-text-input "
                  style={{ width: "50px" }}
                  type="text"
                  placeholder="30"
                  value={camera.face_size_limit}
                  onChange={(e: { target: { value: string } }) =>
                    setCamera({
                      ...camera,
                      face_size_limit: Number(e.target.value),
                    })
                  }
                />
              </Flex>
            </Flex>
          </OverlayTrigger>
          {camera.is_android && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{"Accurate but slower detection mode"}</Tooltip>
              }
            >
              <Flex align="center" gap="8px">
                <div
                  className="text-truncate fw-bolder"
                  title="Accurate face detection"
                  style={{ width: "200px" }}
                >
                  Accurate face detection
                </div>

                <SwitchCheckbox
                  checked={camera.face_detection_accurate_mode || false}
                  onChange={(e) =>
                    setCamera({
                      ...camera,
                      face_detection_accurate_mode: e.target.checked,
                    })
                  }
                />
              </Flex>
            </OverlayTrigger>
          )}
          {!camera.is_android && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{"Change camera angle"}</Tooltip>}
            >
              <Flex align="center" justify="start" gap="8px" className="pb-2">
                <div
                  className="text-truncate fw-bolder"
                  title="Rotate camera"
                  style={{
                    maxWidth: "350px",
                    width: "350px",
                  }}
                >
                  Rotate camera
                </div>
                <Flex w100 gap="2px">
                  <img
                    src={
                      frame
                        ? `${IMAGE_BASE_PATH}${frame}`
                        : CameraFramePlaceHolder
                    }
                    alt="camera-frame"
                    height={isMobile ? 80 : 123}
                    width="auto"
                    className="p-3"
                    style={{
                      borderRadius: "6px",
                      transform: `rotate(${camera.rotate_degree}deg)`,
                    }}
                  />

                  <Flex align="center" gap="4px">
                    <Input
                      className="sr-text-input"
                      style={{ width: "50px" }}
                      type="text"
                      placeholder="30"
                      value={camera.rotate_degree}
                      onChange={(e: { target: { value: string } }) =>
                        setCamera({
                          ...camera,
                          rotate_degree: Number(e.target.value),
                        })
                      }
                    />
                    <Flex>Degree</Flex>
                  </Flex>
                </Flex>
              </Flex>
            </OverlayTrigger>
          )}
        </Flex>
      )}
    </Flex>
  );
}

export default CameraSettingsGeneral;
