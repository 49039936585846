import { bitAfterNow, DEFAULT_STALE_TIME } from "../utils/helper";
import { deleteItem, get, post, put } from "../faceit-api/FaceitApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { PEOPLE_MAX_PAGE } from "./personsContext";
import { AccessRule, AccessRuleUpdate } from "../types/AccessRule";

type CreateAccessRuleProps = {
  accessRuleData: AccessRuleUpdate;
  onSuccessCallback: (accessRule: AccessRule) => void;
  onErrorCallback: (resp: any) => void;
};

type UpdateAccessRuleProps = CreateAccessRuleProps & {
  accessRuleId: string;
};

async function getAccessRules(): Promise<AccessRule[]> {
  return get(
    `access-rules?${new URLSearchParams({ page_size: PEOPLE_MAX_PAGE.toString() }).toString()}`
  ).then((resp) => {
    return resp?.results || [];
  });
}
async function updateAccessRule({
  accessRuleId,
  accessRuleData,
}: UpdateAccessRuleProps) {
  return put(`access-rules/${accessRuleId}`, accessRuleData);
}

async function createAccessRule({ accessRuleData }: CreateAccessRuleProps) {
  return post(`access-rules`, accessRuleData);
}

async function deleteAccessRule({ accessRuleId }: UpdateAccessRuleProps) {
  return deleteItem(`access-rules/${accessRuleId}`);
}

export const useApiAccessRulesData = () => {
  return useQuery("getAccessRules", getAccessRules, {
    keepPreviousData: true,
    initialData: [],
    initialDataUpdatedAt: bitAfterNow(),
    staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
  });
};

export const useApiUpdateAccessRule = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAccessRule, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        const newAccessRule = resp;
        return onSuccessCallback(newAccessRule);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["getAccessRules"] }),
  });
};

export const useApiCreateAccessRule = () => {
  const queryClient = useQueryClient();
  return useMutation(createAccessRule, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["getAccessRules"] }),
  });
};

export const useApiDeleteAccessRule = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteAccessRule, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["getAccessRules"] });
    },
  });
};
