import React, { useEffect, useState } from "react";
import { Flex } from "../elements/flex/Flex";
import { COLORS } from "../../assets/colors";
import { Text } from "../elements/text/Text";
import { LiaTimesSolid } from "react-icons/lia";
import { Ellipse } from "../elements/ellipse/Ellipse";
import { AccessRuleFormStepOne } from "./AccessRuleFormStepOne";
import { AccessRule, AccessRuleUpdate } from "../../types/AccessRule";
import {
  useApiCreateAccessRule,
  useApiUpdateAccessRule,
} from "../../context/accessRuleContext";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import { AccessRuleFormStepTwoCyclical } from "./AccessRuleFormStepTwoCyclical";
import { AccessRuleFormStepTwoOneTime } from "./AccessRuleFormStepTwoOneTime";
import { Modal } from "../elements/modals/Modal";
import { useApiActivityLogsFiltered } from "../../context/activityLogContext";

type Props = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  editAccessRule?: AccessRule;
};

export const AccessRuleFormModal = ({
  showModal,
  setShowModal,
  editAccessRule,
}: Props) => {
  const [step, setStep] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const { refetch } = useApiActivityLogsFiltered({});
  const [accessRuleValues, setAccessRuleValues] = useState<AccessRuleUpdate>(
    {}
  );

  useEffect(() => {
    if (editAccessRule) {
      setAccessRuleValues(editAccessRule);
      setIsEditing(true);
    }
  }, [editAccessRule]);

  const { mutate: createRule, isLoading: isCreatingRule } =
    useApiCreateAccessRule();

  const { mutate: updateRule, isLoading: isUpdatingRule } =
    useApiUpdateAccessRule();

  const handleSaveRule = () => {
    if (isEditing) {
      updateRule({
        accessRuleId: editAccessRule?.id || "",
        accessRuleData: accessRuleValues,
        onSuccessCallback: () => {
          setShowModal(false);
          showSuccessDialog("Access rule updated successfully");
          refetch();
          setAccessRuleValues({});
          setStep(0);
        },
        onErrorCallback: (error) => {
          // handle error
          console.error(error);
          showErrorDialog("Failed to update access rule");
        },
      });
    } else {
      createRule({
        accessRuleData: accessRuleValues,
        onSuccessCallback: () => {
          setShowModal(false);
          showSuccessDialog("Access rule created successfully");
          refetch();
          setAccessRuleValues({});
          setStep(0);
        },
        onErrorCallback: (error) => {
          // handle error
          console.error(error);
          showErrorDialog("Failed to create access rule");
        },
      });
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      keyboard={false}
      size="lg"
      fullscreen={"md-down"}
      aria-modal={true}
      aria-hidden={!showModal}
    >
      <Flex
        column
        style={{
          color: COLORS.FontGray,
          backgroundColor: COLORS.Gray900,
          borderRadius: "8px",
        }}
      >
        <Flex
          w100
          align="center"
          justify="between"
          style={{
            backgroundColor: COLORS.Gray800,
            borderRadius: "8px 8px 0 0",
            padding: "16px",
          }}
        >
          <Text variant="h4">{isEditing ? "Update" : "New"} Access Rule</Text>
          <LiaTimesSolid
            size={24}
            className="pointer"
            onClick={() => setShowModal(false)}
          />
        </Flex>
        <Flex
          align="center"
          justify="center"
          w100
          gap="8px"
          padding="16px 0 0 0"
        >
          <Ellipse
            color={step === 0 ? COLORS.FontGray : COLORS.Gray500}
            size={8}
          />
          {accessRuleValues.rule_type !== "permanent" && (
            <Ellipse
              color={step === 1 ? COLORS.FontGray : COLORS.Gray500}
              size={8}
            />
          )}
        </Flex>
        {step === 0 && (
          <AccessRuleFormStepOne
            accessRuleValues={accessRuleValues}
            setAccessRuleValues={setAccessRuleValues}
            onNext={() =>
              accessRuleValues.rule_type === "permanent"
                ? handleSaveRule()
                : setStep(1)
            }
            onClose={() => setShowModal(false)}
            inProgress={isCreatingRule || isUpdatingRule}
          />
        )}
        {step === 1 && accessRuleValues.rule_type === "cyclical" && (
          <AccessRuleFormStepTwoCyclical
            accessRuleValues={accessRuleValues}
            setAccessRuleValues={setAccessRuleValues}
            onNext={handleSaveRule}
            onBack={() => setStep(0)}
            inProgress={isCreatingRule || isUpdatingRule}
          />
        )}
        {step === 1 && accessRuleValues.rule_type === "one_time" && (
          <AccessRuleFormStepTwoOneTime
            accessRuleValues={accessRuleValues}
            setAccessRuleValues={setAccessRuleValues}
            onNext={handleSaveRule}
            onBack={() => setStep(0)}
            inProgress={isCreatingRule || isUpdatingRule}
          />
        )}
      </Flex>
    </Modal>
  );
};
