import styled from "styled-components";
import { COLORS } from "../../../assets/colors";
import React from "react";

export type ButtonSize = "small" | "medium" | "large";

export type BaseButtonProps = {
  children: React.ReactNode;
  size?: ButtonSize;
  onClick?: () => void;
  className?: string;
  id?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  inProgress?: boolean; // new prop
};

const getPadding = (size: ButtonSize) => {
  if (size === "small") return "6px 20px";
  if (size === "large") return "14px 38px";
  return "10px 28px";
};

export const BaseButton = styled.button<BaseButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: ${(props) => getPadding(props.size || "small")};
  background: ${COLORS.primary};
  border: none;
  border-radius: 8px;
  color: ${COLORS.FontGray};
  font-size: 14px;
  opacity: ${(props) => (props.inProgress ? 0.5 : 1)};
  &:focus {
    outline: none;
  }
  &:hover:enabled {
    background: ${COLORS.PrimaryLight} !important;
    color: ${COLORS.Gray800} !important;
  }
  &:active {
    background: ${COLORS.primaryDark} !important;
  }
  &:disabled {
    background: ${COLORS.Gray500} !important;
    color: ${COLORS.Gray800} !important;
    cursor: not-allowed !important;
  }

  /* Spinner overlay when inProgress is true */
  ${({ inProgress }) =>
    inProgress &&
    `
    pointer-events: none; 
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 16px;
      margin-top: -8px;
      margin-left: -8px;
      border: 3px solid ${COLORS.FontGray};
      border-top: 2px solid transparent;
      border-radius: 50%;
      animation: spin 0.8s linear infinite;
    }

    /* Keep text visible but button disabled, spinner on top */
    @keyframes spin {
      to { transform: rotate(360deg); }
    }
  `}

  ${({ style }) => style && { ...style }};
`;
