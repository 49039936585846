import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../assets/colors";

interface CustomCheckboxProps {
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  disabled?: boolean;
}

const Container = styled.div`
  display: inline-block;
`;

const Label = styled.label<{ disabled?: boolean }>`
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
  `}

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`;

const Checkmark = styled.span<{ disabled?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 13px;
  width: 13px;
  background-color: transparent;
  border: 1px solid
    ${({ disabled }) => (disabled ? COLORS.gray : COLORS.FontGray)};
  border-radius: 4px;
  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 2px;
    width: 4px;
    height: 6px;
    border: solid ${COLORS.FontGray};
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }

  input:checked ~ &::after {
    display: block;
  }
`;

export const Checkbox: React.FC<CustomCheckboxProps> = ({
  checked,
  onChange,
  className,
  disabled,
}) => {
  return (
    <Container className={className}>
      <Label disabled={disabled}>
        <input
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={onChange}
        />
        <Checkmark disabled={disabled} />
      </Label>
    </Container>
  );
};
