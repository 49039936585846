import React, { useState } from "react";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";
import { Camera, RestrictedTime } from "../../types/Camera";
import { Flex } from "../elements/flex/Flex";
import Select from "react-select";
import { Input } from "../elements/input/Input";
import { SecondaryButton } from "../elements/buttons/SecondaryButton";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { RestrictedTimesModal } from "./RestrictedTimesModal";
import { COLORS } from "../../assets/colors";
import { SeparatorVertical } from "../elements/separators/SeparatorVertical";

type CameraSettingsSpatialDetectionProps = {
  camera: Camera;
  setCamera: (camera: Camera) => void;
};

const PPENotifyOnOptions = [
  { value: "disabled", label: "Disabled" },
  { value: "missing_both", label: "Missing vest or helmet" },
  { value: "missing_helmet", label: "Missing helmet" },
  { value: "missing_vest", label: "Missing vest" },
];

const defaultPPESettings = {
  enabled: false,
  threshold: 0.7,
};

export function CameraSettingsSpatialDetection(
  props: CameraSettingsSpatialDetectionProps
) {
  const { camera, setCamera } = props;
  const [showAddRestrictedAreaModal, setShowAddRestrictedAreaModal] =
    useState(false);
  const personTrackingSettings = camera?.person_tracking;
  const ppeDetectionSettings = camera?.ppe_detection;

  const handleAddRestrictedArea = (restrictedTime: RestrictedTime) => {
    setCamera({
      ...camera,
      restricted_times: [...(camera?.restricted_times || []), restrictedTime],
    });
    setShowAddRestrictedAreaModal(false);
  };

  return (
    <Flex
      column
      gap="8px"
      className="py-2"
      style={{
        fontSize: "14px",
      }}
    >
      <Flex align="center" gap="8px">
        <div
          className="text-truncate fw-bolder"
          title="Enable person tracking"
          style={{ width: "200px" }}
        >
          Enable person tracking
        </div>

        <SwitchCheckbox
          checked={!!personTrackingSettings?.enabled}
          onChange={(e) =>
            setCamera({
              ...camera,
              person_tracking: {
                ...camera.person_tracking,
                enabled: e.target.checked,
              },
            })
          }
        />
      </Flex>
      <Flex align="center" gap="8px">
        <label
          style={{ width: "200px" }}
          className={`text-truncate ${!camera?.person_tracking?.enabled ? "sr-disabled" : ""}`}
          title="Threshold of an algorithm that calculates the probability of making a recognition on a certain face-event"
        >
          Recognizable Threshold
        </label>
        <Input
          style={{ width: "50px" }}
          disabled={!camera?.person_tracking?.enabled}
          type="number"
          placeholder="0.5"
          value={camera?.person_tracking?.recognizable_threshold || 0.5}
          onChange={(e) =>
            setCamera({
              ...camera,
              person_tracking: {
                ...camera.person_tracking,
                recognizable_threshold: parseFloat(e.target.value),
              },
            })
          }
        />
      </Flex>
      <Flex align="center" gap="8px">
        <div
          className={`text-truncate fw-bolder ${!camera?.person_tracking?.enabled ? "sr-disabled" : ""}`}
          title="Enable PPE detection"
          style={{ width: "200px" }}
        >
          Enable PPE detection
        </div>

        <SwitchCheckbox
          checked={!!ppeDetectionSettings?.enabled}
          disabled={!camera?.person_tracking?.enabled}
          onChange={(e) =>
            setCamera({
              ...camera,
              ppe_detection: {
                ...(camera.ppe_detection || defaultPPESettings),
                enabled: e.target.checked,
              },
            })
          }
        />
      </Flex>
      <Flex align="center" gap="8px">
        <label
          className={`text-truncate ${!camera?.person_tracking?.enabled || !camera?.ppe_detection?.enabled ? "sr-disabled" : ""}`}
          title="PPE detection threshold"
          style={{
            width: "200px",
          }}
        >
          PPE detection threshold
        </label>
        <Input
          disabled={
            !camera?.person_tracking?.enabled || !camera?.ppe_detection?.enabled
          }
          style={{ width: "50px" }}
          type="number"
          placeholder="0.7"
          value={camera?.ppe_detection?.threshold || 0.7}
          onChange={(e) =>
            setCamera({
              ...camera,
              ppe_detection: {
                ...(camera.ppe_detection || defaultPPESettings),
                threshold: parseFloat(e.target.value),
              },
            })
          }
        />
      </Flex>
      <Flex align="center" gap="8px">
        <label
          className={`text-truncate ${!camera?.person_tracking?.enabled || !camera?.ppe_detection?.enabled ? "sr-disabled" : ""}`}
          title="On which event to notify about PPE"
          style={{
            width: "200px",
          }}
        >
          PPE Notify on:
        </label>
        <div style={{ width: "300px" }}>
          <Select
            className="react-select-container"
            isDisabled={
              !camera?.person_tracking?.enabled ||
              !camera?.ppe_detection?.enabled
            }
            classNamePrefix="react-select"
            placeholder="Select PPE notification type"
            value={PPENotifyOnOptions.find(
              (o) => ppeDetectionSettings?.notify_on === o.value
            )}
            onChange={(e) =>
              setCamera({
                ...camera,
                ppe_detection: {
                  ...camera.ppe_detection,
                  notify_on:
                    (e?.value as typeof ppeDetectionSettings.notify_on) ||
                    "disabled",
                },
              })
            }
            options={PPENotifyOnOptions}
          />
        </div>
      </Flex>
      <Flex align="center" gap="8px">
        <div
          className={`text-truncate fw-bolder ${!camera?.person_tracking?.enabled ? "sr-disabled" : ""}`}
          title="Enable PPE detection"
          style={{ width: "200px" }}
        >
          Set restricted area
        </div>
        <SecondaryButton onClick={() => setShowAddRestrictedAreaModal(true)}>
          <FaPlus />
        </SecondaryButton>
      </Flex>
      <Flex column gap="8px" className="mb-3">
        {camera?.restricted_times?.map((area, index) => (
          <Flex
            key={index}
            className="text-truncate"
            align="center"
            justify="between"
            gap="8px"
            padding="8px 16px"
            style={{
              margin: "0 16px",
              border: `1px solid ${COLORS.Gray500}`,
              borderRadius: "8px",
            }}
          >
            <Flex align="center" gap="8px">
              <b>Day: </b>
              <label className="text-capitalize">
                {area.day === "all" ? "all days" : area.day}
              </label>
              <SeparatorVertical height="16px" color={COLORS.Gray500} />
              <b>Time: </b>
              {area.is_all_day
                ? "All day"
                : `${area.start_time} - ${area.end_time}`}
            </Flex>
            <FaTrashAlt
              className="pointer"
              onClick={() =>
                setCamera({
                  ...camera,
                  restricted_times: camera?.restricted_times?.filter(
                    (a) => a !== area
                  ),
                })
              }
            />
          </Flex>
        ))}
      </Flex>
      {showAddRestrictedAreaModal && (
        <RestrictedTimesModal
          onClose={() => setShowAddRestrictedAreaModal(false)}
          onSave={(restrictedArea) => handleAddRestrictedArea(restrictedArea)}
        />
      )}
    </Flex>
  );
}
