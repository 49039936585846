import { COLORS } from "../../assets/colors";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { exportPersons } from "../../faceit-api/FaceitApi";
import {
  FaChevronDown,
  FaDownload,
  FaFilter,
  FaList,
  FaSpinner,
  FaTh,
  FaUpload,
  FaUserPlus,
} from "react-icons/fa";
import "./People.css";
import { DataContext } from "../../App";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import PeopleAddPopup from "./PeopleAddPopup";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { showErrorDialog } from "../../popups/opretaions";
import ImportPeoplePopup from "./ImportPeoplePopup";
import PersonModal from "../face/PersonModal";
import PeopleTable from "./PeopleTable";
import PeopleGrid from "./PeopleGrid";
import { BsPersonBoundingBox } from "react-icons/bs";
import PredictPopup from "../predict/PredictPopup";
import { useApiPersonsData } from "../../context/personsContext";
import { useApiGroupsData } from "../../context/groupContext";
import { SecondaryButton } from "../elements/buttons/SecondaryButton";
import { useIsMobile } from "../../utils/hooks";
import { Menu } from "../elements/menu/Menu";
import { MenuPopup } from "../elements/menu/MenuPopup";
import { MenuItem } from "../elements/menu/MenuItem";
import { Person } from "../../types/Person";
import { Group } from "../../types/Group";
import { Option } from "../../types/Shared";
import { Flex } from "../elements/flex/Flex";
import { SearchInput } from "../elements/input/SearchInput";

const People = () => {
  const { userInfo } = useContext(DataContext);
  const isMobile = useIsMobile();
  const { data: groups } = useApiGroupsData();
  const { data: persons, isFetching: isFetchingPersons } = useApiPersonsData();

  const [showAddPersonModal, setShowAddPersonModal] = useState(false);
  const [people, setPeople] = useState<Person[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [searchName, setSearchName] = useStateWithCallbackLazy("");
  const [searchTagId, setSearchTagId] = useStateWithCallbackLazy("");
  const [selectedGroups, setSelectedGroups] = useStateWithCallbackLazy<
    string[]
  >([]);
  const [isExporting, setIsExporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [showImportPeopleModal, setShowImportPeopleModal] = useState(false);
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showPredictPopup, setShowPredictPopup] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [selectedPerson, setSelectedPerson] = useState<Person | undefined>(
    undefined
  );
  const [isGridView, setIsGridView] = useState(
    isMobile || sessionStorage.getItem("peopleViewGrid") === "true"
  );

  useEffect(() => {
    setPeople(persons || []);
    setTotalRows(persons?.length || 0);
  }, [persons]);

  useEffect(() => {
    setIsGridView(
      isMobile || sessionStorage.getItem("peopleViewGrid") === "true"
    );
  }, [isMobile]);

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (value === "") {
      setPeople(persons || []);
      setTotalRows(persons?.length || 0);
    }
    var peopleToSet = [...(persons || [])];
    peopleToSet = peopleToSet.filter((p) =>
      JSON.stringify(p).toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );

    setTotalRows(peopleToSet.length);
    setPeople(peopleToSet);
  };

  const onFilter = (
    searchText: string,
    searchTagId: string,
    selectedGroups: string[]
  ) => {
    var peopleToSet = [...(persons || [])];

    if (searchText) {
      peopleToSet = peopleToSet.filter((p) =>
        p?.person_name?.toLowerCase().includes(searchText?.toLowerCase())
      );
    }
    if (searchTagId) {
      peopleToSet = peopleToSet.filter((p) =>
        p?.tag_id?.toLowerCase().includes(searchTagId?.toLowerCase())
      );
    }
    if (selectedGroups.length > 0) {
      peopleToSet = peopleToSet.filter((p) => selectedGroups.includes(p.group));
    }
    setTotalRows(peopleToSet.length);
    setPeople(peopleToSet);
  };

  const groupsOptions: Option[] = (groups as Group[]).map((g) => {
    return {
      label: g.name,
      value: g.id,
    };
  });

  const exportPeople = () => {
    setIsExporting(true);
    exportPersons()
      .then((response) => response.blob())
      .then((blob) => {
        setIsExporting(false);
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `PeopleList.zip`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
      })
      .catch((err) => {
        console.error(err);
        showErrorDialog("Failed to export people list");
        setIsExporting(false);
      });
  };

  // createTheme creates a new theme named solarized that overrides the build in dark theme
  const animatedComponents = makeAnimated();

  return (
    <div className="mt-2" style={{ padding: "20px" }}>
      <div
        className={`d-flex align-items-center justify-content-${
          isMobile ? "around flex-wrap" : "between"
        } mb-3`}
        style={{ color: COLORS.FontGray }}
      >
        <Modal
          show={showAddPersonModal}
          onHide={() => setShowAddPersonModal(false)}
          backdrop="static"
          keyboard={false}
          size="xl"
          fullscreen={"md-down"}
        >
          <PeopleAddPopup
            close={() => setShowAddPersonModal(false)}
            groupsOptions={groupsOptions}
          />
        </Modal>

        <Modal
          show={showImportPeopleModal}
          onHide={() => setShowImportPeopleModal(false)}
          backdrop="static"
          keyboard={false}
          size="sm"
          fullscreen={"md-down"}
        >
          <ImportPeoplePopup
            close={() => setShowImportPeopleModal(false)}
            setIsImporting={setIsImporting}
            isImporting={isImporting}
          />
        </Modal>

        <Modal
          show={showPersonModal}
          onHide={() => setShowPersonModal(false)}
          keyboard={false}
          size="xl"
          fullscreen={"md-down"}
        >
          {!!selectedPerson ? (
            <PersonModal
              close={() => setShowPersonModal(false)}
              person={selectedPerson}
            />
          ) : (
            <></>
          )}
        </Modal>

        <Modal
          show={showPredictPopup}
          onHide={() => setShowPredictPopup(false)}
          backdrop="static"
          keyboard={false}
          fullscreen={"md-down"}
        >
          <PredictPopup close={() => setShowPredictPopup(false)} />
        </Modal>
        <Flex
          gap="24px"
          column={isMobile}
          align={isMobile ? "start" : "center"}
        >
          <Menu
            position={isMobile ? "bottom left" : "right center"}
            width="220px"
            trigger={
              <div style={{ width: "fit-content", position: "relative" }}>
                <SecondaryButton className="text-uppercase  text-truncate ">
                  ACTIONS
                  <FaChevronDown className="ms-2" />
                </SecondaryButton>
              </div>
            }
          >
            <MenuPopup
              items={[
                <MenuItem
                  text="Add New Person"
                  icon={<FaUserPlus />}
                  onClick={() => setShowAddPersonModal(true)}
                  disabled={groups?.length === 0 || !userInfo?.is_staff}
                />,
                <MenuItem
                  text="Identify Face Image"
                  icon={<BsPersonBoundingBox />}
                  onClick={() => setShowPredictPopup(true)}
                />,
                <MenuItem
                  text="Export People List"
                  onClick={exportPeople}
                  disabled={!userInfo?.is_staff}
                  icon={
                    isExporting ? (
                      <FaSpinner className="fa-spin" />
                    ) : (
                      <FaDownload />
                    )
                  }
                />,
                <MenuItem
                  text="Import People List"
                  onClick={() => setShowImportPeopleModal(true)}
                  disabled={!userInfo?.is_staff}
                  icon={
                    isImporting ? (
                      <FaSpinner className="fa-spin" />
                    ) : (
                      <FaUpload />
                    )
                  }
                />,
              ]}
            />
          </Menu>
          <SearchInput
            style={{
              minWidth: "250px",
            }}
            value={searchText}
            onChange={(e) => handleSearch(e.target.value as string)}
          />

          <Flex
            align="center"
            gap="8px"
            className="pointer"
            style={{
              width: "200px",
            }}
            onClick={() => setShowFilters(!showFilters)}
          >
            <FaFilter color={COLORS.PrimaryLight} />
            <div>{`${showFilters ? "Hide " : ""}Filters`}</div>
          </Flex>
        </Flex>
        <div className="d-flex align-items-center gap-16">
          <div
            className="d-flex align-items-center gap-2 p-2"
            style={{
              fontSize: "14px",
            }}
          >
            <span>Results count:</span>
            <strong>{totalRows}</strong>
          </div>
          {!isMobile && (
            <div
              style={{
                borderLeft: "1px solid",
                borderLeftColor: COLORS.Gray600,
                height: "16px",
              }}
            ></div>
          )}
          {!isMobile && (
            <div className="col mb-1 d-flex align-items-end justify-content-end">
              <div className="form-group btn-group">
                <button
                  className="sr-btn p-2 d-flex align-items-center"
                  disabled={!isGridView}
                  onClick={() => {
                    sessionStorage.setItem("peopleViewGrid", "false");
                    setIsGridView(false);
                  }}
                  style={{
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                >
                  <FaList />
                </button>
                <button
                  className="sr-btn p-2 d-flex align-items-center"
                  onClick={() => {
                    sessionStorage.setItem("peopleViewGrid", "true");
                    setIsGridView(true);
                  }}
                  style={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                  }}
                  disabled={isGridView}
                >
                  <FaTh />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {showFilters && (
        <div className={`d-flex ${isMobile ? "flex-column" : "gap-16 pb-2"}`}>
          <div className="col mb-1">
            <label
              style={{ color: COLORS.Gray50, fontSize: "14px" }}
              className="mb-1"
            >
              Filter by name:
            </label>
            <input
              type="text"
              id="filter-person-name"
              placeholder="Type a person name..."
              onChange={(e) =>
                setSearchName(e.target.value, () =>
                  onFilter(e.target.value, searchTagId, selectedGroups)
                )
              }
              className="sr-text-input"
              value={searchName || ""}
            />
          </div>
          <div className="col mb-1">
            <label
              style={{ color: COLORS.Gray50, fontSize: "14px" }}
              className="mb-1"
            >
              Filter by tag id:
            </label>
            <input
              type="text"
              id="filter-person-tag-id"
              placeholder="Type a tag id..."
              onChange={(e) =>
                setSearchTagId(e.target.value, () =>
                  onFilter(searchName, e.target.value, selectedGroups)
                )
              }
              className="sr-text-input"
              value={searchTagId || ""}
            />
          </div>
          <div className="col mb-1">
            <label
              style={{ color: COLORS.Gray50, fontSize: "14px" }}
              className="mb-1"
            >
              Filter by groups:
            </label>
            <Select
              className="react-select-container w-100"
              classNamePrefix="react-select"
              id="filter-select-group"
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={groupsOptions}
              onChange={(options) => {
                setSelectedGroups(
                  options.map((o) => o.value.trim()),
                  () =>
                    onFilter(
                      searchName,
                      searchTagId,
                      options.map((o) => o.value.trim())
                    )
                );
              }}
              value={groupsOptions.filter((o) =>
                selectedGroups.includes(o.value)
              )}
            />
          </div>
        </div>
      )}
      <hr style={{ height: "2px", color: COLORS.FontGray }} />
      {isFetchingPersons && (
        <div className="text-center mt-2">
          <FaSpinner
            style={{ color: COLORS.Gray50 }}
            className="fa-spin mb-2 fs-3"
          />
        </div>
      )}

      {isGridView ? (
        <PeopleGrid
          people={people}
          setSelectedPerson={setSelectedPerson}
          setShowPersonModal={setShowPersonModal}
        />
      ) : (
        <PeopleTable
          people={people}
          totalRows={totalRows}
          setSelectedPerson={setSelectedPerson}
          setShowPersonModal={setShowPersonModal}
        />
      )}
    </div>
  );
};

export default People;
