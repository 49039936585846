import React, { Dispatch, SetStateAction } from "react";
import { Flex } from "../elements/flex/Flex";
import { Text } from "../elements/text/Text";
import { Input } from "../elements/input/Input";
import {
  AccessRuleType,
  accessRuleTypeOptions,
  AccessRuleUpdate,
} from "../../types/AccessRule";
import { Select } from "../elements/select/Select";
import { useApiCamerasData } from "../../context/camerasContext";
import { useApiPersonsData } from "../../context/personsContext";
import { useApiGroupsData } from "../../context/groupContext";
import { Checkbox } from "../elements/checkbox/Checkbox";
import { SecondaryButton } from "../elements/buttons/SecondaryButton";
import { MainButton } from "../elements/buttons/MainButton";
import { CircularProgress } from "@mui/material";

type Props = {
  accessRuleValues: AccessRuleUpdate;
  setAccessRuleValues: Dispatch<SetStateAction<AccessRuleUpdate>>;
  onNext: () => void;
  onClose: () => void;
  inProgress: boolean;
};

const textWidth = "100px";
const inputWidth = "300px";

export const AccessRuleFormStepOne = ({
  accessRuleValues,
  setAccessRuleValues,
  onNext,
  onClose,
  inProgress,
}: Props) => {
  const { data: cameras, isFetching: isFetchingCameras } = useApiCamerasData();
  const { data: persons, isFetching: isFetchingPerson } = useApiPersonsData();
  const { data: groups, isFetching: isFetchingGroups } = useApiGroupsData();
  const zones: string[] = Array.from(
    new Set(cameras?.filter((c) => c.zone)?.map((camera) => camera.zone) || [])
  ) as string[];

  const whereOptions = [
    {
      label: "Zones",
      options: zones.map((zone) => ({
        value: zone,
        label: zone,
      })),
    },
    {
      label: "Cameras",
      options:
        cameras?.map((camera) => ({
          value: camera.id,
          label: camera.name,
        })) || [],
    },
  ];

  const whoOptions = [
    {
      label: "Groups",
      options:
        groups?.map((group) => ({
          value: group.id,
          label: group.name,
        })) || [],
    },
    {
      label: "Persons",
      options:
        persons?.map((person) => ({
          value: person.id,
          label: person.person_name,
        })) || [],
    },
  ];

  const isZone = (value: string) => {
    return zones.includes(value);
  };

  const isGroup = (value: string) => {
    return groups?.map((group) => group.id).includes(value);
  };

  const isValidForm = (): boolean => {
    return (
      !!accessRuleValues.name &&
      !!accessRuleValues.rule_type &&
      (!!accessRuleValues.camera || !!accessRuleValues.zone) &&
      (!!accessRuleValues.person || !!accessRuleValues.group) &&
      (!!accessRuleValues.should_access || !!accessRuleValues.should_notify)
    );
  };

  return (
    <Flex padding="24px" column gap="16px">
      {isFetchingCameras || isFetchingPerson || isFetchingGroups ? (
        <Flex w100 align="center" column>
          <CircularProgress />
        </Flex>
      ) : (
        <> </>
      )}
      <Flex align="center" gap="8px">
        <Text
          variant="body1"
          style={{
            width: textWidth,
          }}
        >
          Name
        </Text>
        <Input
          style={{
            maxWidth: inputWidth,
          }}
          value={accessRuleValues.name}
          placeholder="Enter a name"
          onChange={(e) =>
            setAccessRuleValues({
              ...accessRuleValues,
              name: e.target.value,
            })
          }
        />
      </Flex>
      <Flex align="center" gap="8px">
        <Text
          variant="body1"
          style={{
            width: textWidth,
          }}
        >
          Type
        </Text>
        <Select
          style={{
            width: "100%",
            maxWidth: inputWidth,
          }}
          value={accessRuleTypeOptions.find(
            (ar) => ar.value === accessRuleValues.rule_type
          )}
          options={accessRuleTypeOptions}
          placeholder="Select a type"
          onChange={(option) =>
            setAccessRuleValues({
              ...accessRuleValues,
              rule_type: option?.value as AccessRuleType,
            })
          }
        />
      </Flex>

      <Flex align="center" gap="8px">
        <Text
          variant="body1"
          style={{
            width: textWidth,
          }}
        >
          Location
        </Text>
        <Select
          style={{
            width: "100%",
            maxWidth: inputWidth,
          }}
          isSearchable
          value={whereOptions
            .flatMap((o) => o.options)
            .find(
              (ar) =>
                ar.value === accessRuleValues.camera ||
                ar.value === accessRuleValues.zone
            )}
          options={whereOptions as any}
          placeholder="Select a Zone or Camera"
          onChange={(option) =>
            setAccessRuleValues({
              ...accessRuleValues,
              camera: isZone(option?.value || "") ? undefined : option?.value,
              zone: isZone(option?.value || "") ? option?.value : undefined,
            })
          }
        />
      </Flex>

      <Flex align="center" gap="8px">
        <Text
          variant="body1"
          style={{
            width: textWidth,
          }}
        >
          Apply On
        </Text>
        <Select
          style={{
            width: "100%",
            maxWidth: inputWidth,
          }}
          isSearchable
          value={whoOptions
            .flatMap((o) => o.options)
            .find(
              (ar) =>
                ar.value === accessRuleValues.person ||
                ar.value === accessRuleValues.group
            )}
          options={whoOptions as any}
          placeholder="Select a Person or Group"
          onChange={(option) =>
            setAccessRuleValues({
              ...accessRuleValues,
              person: isGroup(option?.value || "") ? undefined : option?.value,
              group: isGroup(option?.value || "") ? option?.value : undefined,
            })
          }
        />
      </Flex>
      <Flex align="center" gap="8px">
        <Text
          variant="body1"
          style={{
            width: textWidth,
          }}
        >
          Action
        </Text>
        <Flex column>
          <Flex align="center" gap="8px">
            <Checkbox
              checked={!!accessRuleValues?.should_access}
              onChange={(e) => {
                setAccessRuleValues({
                  ...accessRuleValues,
                  should_access: e.target.checked,
                });
              }}
            />
            <Text variant="body1">Access</Text>
          </Flex>

          <Flex align="center" gap="8px">
            <Checkbox
              checked={!!accessRuleValues?.should_notify}
              onChange={(e) => {
                setAccessRuleValues({
                  ...accessRuleValues,
                  should_notify: e.target.checked,
                });
              }}
            />
            <Text variant="body1">Alert</Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex align="center" justify="between" w100 gap="24px">
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <MainButton
          disabled={!isValidForm()}
          onClick={onNext}
          inProgress={inProgress}
        >
          {accessRuleValues.rule_type === "permanent" ? "Save" : "Next"}
        </MainButton>
      </Flex>
    </Flex>
  );
};
